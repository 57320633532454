import React from "react";
/* import { createRoot } from 'react-dom/client' */
import ReactDOM from "react-dom";
import { BrowserRouter as Router,} from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from './translations/es/global.json'
import global_en from './translations/en/global.json'
import landing_es from './translations/es/landing.json'
import landing_en from './translations/en/landing.json'
import projects_es from './translations/es/projects.json'
import projects_en from './translations/en/projects.json'
import mytasks_es from './translations/es/mytasks.json'
import mytasks_en from './translations/en/mytasks.json'
import productivity_es from './translations/es/productivity.json'
import productivity_en from './translations/en/productivity.json'
import onBoarding_es from './translations/es/onBoarding.json'
import onBoarding_en from "./translations/en/onBoarding.json";
import users_es from "./translations/es/users.json";
import users_en from "./translations/en/users.json"
import login_es from './translations/es/login.json'
import login_en from './translations/en/login.json'
import create_es from './translations/es/create_project.json'
import create_en from './translations/en/create_project.json'
import boards_es from './translations/es/boards.json'
import boards_en from './translations/en/boards.json'
import schedule_es from './translations/es/schedule.json'
import schedule_en from './translations/en/schedule.json'
import tags_es from './translations/es/tags.json'
import tags_en from './translations/en/tags.json'
import planning_es from './translations/es/planning.json'
import planning_en from './translations/en/planning.json'

import 'react-datepicker/dist/react-datepicker.module.css'

i18next.init({
  interpolation : {escapeValue: false},
  lng:"es",
  resources:{
    es:{
      global : global_es,
      landing : landing_es,
      projects : projects_es,
      mytasks : mytasks_es,
      productivity : productivity_es,
      onBoarding : onBoarding_es,
      users : users_es,
      login : login_es,
      create : create_es,
      boards : boards_es,
      schedule : schedule_es,
      tags : tags_es,
      planning : planning_es,
    },
    en:{
      global : global_en,
      landing : landing_en,
      projects : projects_en,
      mytasks : mytasks_en,
      productivity : productivity_en,
      onBoarding : onBoarding_en, 
      users : users_en,
      login : login_en,
      create : create_en,
      boards : boards_en,
      schedule : schedule_en,
      tags : tags_en,
      planning : planning_en,
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
  <I18nextProvider i18n={i18next}>
    <Router>
    <App />
    </Router>
  </I18nextProvider>
  </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
/* const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
  <Provider store={store}>
  <I18nextProvider i18n={i18next}>
    <Router>
    <App />
    </Router>
  </I18nextProvider>
  </Provider>
  </React.StrictMode>
)
 */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// React.StricMode
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
