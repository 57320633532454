import React from "react";
import { Gantt } from "dhtmlx-gantt";
import { gantt } from "dhtmlx-gantt";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css" 
import "dhtmlx-gantt/codebase/skins/dhtmlxgantt_new_material.css";
import "../../css/custom.css"
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { API_ROUTE, calcularDuracionV2, getResourceNames, headersRequest, inputChange, removeDataBaseline, saveData } from "../../helpers";
import axios from "axios";
import { useRef } from "react";
import moment from "moment";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import NotesModal from "../NotesModal";
import { Link, useParams } from "react-router-dom";
import ContextMenu, { ContextMenuTypes } from "devextreme-react/context-menu";
import _ from "lodash";
import swal from "sweetalert";
import DatePickerBlock from "./DatePickerBlock";
import  {autoFormatter, dayFormatter, hourFormatter} from "./helpersToGantt"
import GanttTest from "./GanttTest";
import { formatISO, parse, setHours, setMinutes } from 'date-fns';



const GanttDxClass = (props) => {
  const [t, i18n] = useTranslation("schedule");
  const [prueba, setAllAct] = useState([]);
  const excludedDates = useRef([]);
  const companyResources = useRef([]);
  const [savebaseline, setSaveBaseline] = useState({baseline:""})
  const [savebaselineCopyTo, setSaveBaselineCopyTo] = useState({copy_baseline_to:""})
  const [savebaselineCopyFrom, setSaveBaselineCopyFrom] = useState({copy_baseline_from:""})
  const [show, setShow] = useState(false);
  const [showBaseline, setShowBaseLine] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [taskForBaseline,setTaskForBaseLine] = useState([])
  const [activeAutoSchedule, setActiveAutoSchedule] = useState(false)
  const [valiExcepcion, setValiExcepcion] = useState ([])

  const handleShowBaseline = () => { 
    getGanttDataTasks()
    setShowBaseLine(true)
  } 
  const handleCloseBaseline = () =>{
    setSaveBaseline({baseline:""})
    setSaveBaselineCopyTo({copy_baseline_to:""})
    setSaveBaselineCopyFrom({copy_baseline_from:""})
    setShowBaseLine(false)
  } 

  const [taskEdit, setTaskEdit] = useState({});
  let { uid } = useParams();

  const [showBaselineGantt, setShowBaselineGantt] = useState({showbaseline :"showbaseline_0"})

  const [showColumns, setShowColumns] = useState({
    activeWbs : false,
    activeTask : false,
    activeStart: false,
    activeEnd:false,
    activeDuration: false,
    activeUnique : true,
    activeCost :true,
    activeCostReal:true,
    activePrede: false,
    activeReal : false,
    activePlan :false,
    activeIndi : false,
    actLB_start_1 : false,
    actLB_end_1 : false,
    actLB_duration_1 :false,
    actLB_cost_1 : false,
    actLB_start_2 : true,
    actLB_end_2 : true,
    actLB_duration_2 : true,
    actLB_cost_2 : true,
    actLB_start_3 : true,
    actLB_end_3 : true,
    actLB_duration_3 : true,
    actLB_cost_3 : true,
    actLB_start_4 : true,
    actLB_end_4 :true,
    actLB_duration_4 : true,
    actLB_cost_4: true,
    actLB_start_5 : true,
    actLB_end_5: true,
    actLB_duration_5 :true,
    actLB_cost_5 : true,
    active_duration_fci: props.uid == '7EAE00F6-6402-4D72-9492-150BD4596C9F' ? false : true
  })

  const [showDuration, setShowDuration] = useState(false)

  const toggleColum = (column) =>{
    console.log(column)
    setShowColumns((prev)=>({
      ...prev, 
        [column]: !prev[column]
      
    }))
  }
  
  const ganttContainer = useRef(null)
  const fileinput = useRef(null)

  useEffect(() => {

    /* const gantt = Gantt.getGanttInstance() */
    //modal Edit task
    let user_lg = window.localStorage.getItem("idioma");
    /*     let gantt = Gantt.getGanttInstance()
        console.log("gantt",Gantt) */
    setLightBoxSections();
    sectionForMilestonesProjects();
    sectionForProjects();
    /*  sectionForMilestonesProjects()
        sectionForProjects() */
   /*  configColumns(); */
    getGanttData();

    gantt.plugins({
      auto_scheduling: true,
      click_drag: true,
      critical_path: true,
      drag_timeline: true,
      fullscreen: true,
      grouping: true,
      keyboard_navigation: true,
      marker: true,
      multiselect: true,
      overlay: true,
      quick_info: false,
      tooltip: true,
      undo: true,
      export_api: true,
    });

    //funcionalidades avanzadas
   /*  gantt.config.auto_scheduling = activeAutoSchedule; //1
    gantt.config.auto_scheduling_strict = activeAutoSchedule;
    gantt.config.auto_scheduling_compatibility = activeAutoSchedule;
    gantt.config.auto_scheduling_initial = activeAutoSchedule; */
    gantt.config.order_branch = false;
    gantt.config.bar_height = 25;
    gantt.config.row_height = 40;
    gantt.config.show_progress = true;
    gantt.config.resource_property = "owner_id";
    gantt.config.order_branch_free = false;
    gantt.config.drag_progress = true;
    gantt.ext.zoom.init(zoomConfig);
    gantt.ext.zoom.setLevel("quarter");
    gantt.i18n.setLocale(user_lg);
    gantt.serverList("projectId", [props.uid]);
   /*  gantt.config.autosize = true; */
    gantt.config.auto_types = false;
    gantt.config.keyboard_navigation_cells = true;
    gantt.config.reorder_grid_columns = true;
    gantt.config.smart_rendering =true
    gantt.config.show_task_cells  = true
    gantt.ext.fullscreen.getFullscreenElement = () => {
      return document.getElementById("show-btns-gantt");
    };
    const lenguajeRol = window.localStorage.getItem("idioma");
    gantt.serverList("indicator", [
      { key: 1, label: lenguajeRol == "es" ? "Al día" : "On time" },
      { key: 2, label: lenguajeRol == "es" ? "Moderado" : "Moderate" },
      { key: 3, label: lenguajeRol == "es" ? "Crítico" : "Critical" },
      { key: 4, label: lenguajeRol == "es" ? "Ext. Crítico" : "Critical ext." },
    ]);

    gantt.config.work_time = true;
    const rol = window.localStorage.getItem("rol");
    gantt.config.readonly = (rol == "Ejecutivo")? true : false;

    //config durarion time 
   /*  gantt.config.duration_unit = "hour"
    gantt.config.time_step  = 1
    gantt.config.round_dnd_dates = false
    gantt.setWorkTime({hours:[8,12,13,17]})
    gantt.setWorkTime({day:0,hours:false}) 
    gantt.setWorkTime({day:6,hours:false}) */ 

    gantt.config.duration_unit ="hour"
    gantt.config.time_step  = 8
    gantt.setWorkTime({hours:["8:00-12:00","13:00-17:00"]})
    gantt.setWorkTime({day:0,hours:false})    // Sunday day off
    gantt.setWorkTime({day:6,hours:false})

    /* gantt.config.readonly_property = rol == "Miembro de Equipo"? "duration":"" */
    /* gantt.config.readonly = rol == "Miembro de Equipo"? true : false */
    /* gantt.config.drag_progress = false; */
    /* gantt.config.readonly_property ="duration"
        gantt.config.readonly_property= "wbs"
        gantt.config.readonly_property = "text" */

    //limpiar fechas cuando se cancela
    gantt.attachEvent("onLightboxCancel", function(id){

    })
    //fecha del dia corte
    let dateToStr = gantt.date.date_to_str(gantt.config.task_date);
    let today_marker = new Date();
    gantt.addMarker({
      start_date: today_marker,
      css: "status_line",
      text: t("schedule_gantt_today"),
      title: `Fecha : ${dateToStr(today_marker)}`,
    });

    gantt.addCalendar({
      id: "custom",
    });
   /*  gantt
      .getCalendar("custom")
      .setWorkTime({ date: new Date("2025", "02", "31"), hours: false });
    gantt
      .getCalendar("custom")
      .setWorkTime({ date: new Date("2025", "03", "01"), hours: false });
    gantt
      .getCalendar("custom")
      .setWorkTime({ date: new Date("2025", "03", "04"), hours: false }); */

    /* gantt.addMarker({
      start_date: new Date("2025", "03", "04"),
      css: "today",
      text: "Today",
    }); */

    gantt.templates.scale_cell_class = function (date) {
      if (!gantt.isWorkTime(date)) return "weekend";
    };

    gantt.templates.timeline_cell_class = function (item, date) {
      if (!gantt.isWorkTime({ date: date, task: item })) return "weekend";
    };

    let eventIDs = [];
    const onTaskClick = gantt.attachEvent("onTaskClick", function (id, e) {
      /* gantt.message("onTaskClick: " + id) */
      return true;
    });

    eventIDs.push(onTaskClick);

    let filterValue = ""
    let delay
    gantt.$doFilter = function(value){
      filterValue = value
      clearTimeout(delay)
      delay = setTimeout(function(){
        gantt.render();
        gantt.$root.querySelector("[data-text-filter]").focus()
      },200)
    }
    const onBeforeTaskDisplay = gantt.attachEvent("onBeforeTaskDisplay", function(id,task) {
      if(!filterValue) return true
      let normalizeText = task.text.toLowerCase()
      let normalizeValue = filterValue.toLowerCase()
      return normalizeText.indexOf(normalizeValue) > -1
    })
    gantt.attachEvent("onGanttRender", function(){
      if(filterValue){
        gantt.$root.querySelector("[data-text-filter]").value = filterValue
      }
     
    })

    
    eventIDs.push(onBeforeTaskDisplay);

    /* gantt.config.order_branch = true; */
    // restore the order of tasks
    const onLoadEnd = gantt.attachEvent("onLoadEnd", function () {
      gantt.batchUpdate(function () {
        gantt.sort("sort_order", false);
        gantt.sort("sort_order", false);
      });
    });
    eventIDs.push(onLoadEnd);
    //btn for customize notes
    gantt.attachEvent("onTaskClick", function (id, e) {
      let task = gantt.getTask(id);
      setTaskEdit({
        IdProyecto: uid,
        IdActividad: id,
        taskName: task?.text,
      });
      /* props.taskEdit.IdProyecto, props.taskEdit.IdActividad */
      return true;
    });
    gantt.config.quickinfo_buttons = [
      "icon_delete",
      "icon_edit",
      "notes_button",
    ];
    gantt.locale.labels["notes_button"] = "Notas";
    gantt.$click.buttons.notes_button = function (id) {
      /*  gantt.message("These are advanced details"); */
      let task = gantt.getTask(id);
      if(task.readonly){
        return false
      }else {
         handleShow();
         return false
      }
   
      return false; //blocks the default behavior
    };

    //layout
    /* gantt.config.layout = {
      css: "gantt_container", //funcionando
      cols: [
        {
          width: 400,
          min_width: 300,
          rows: [
            {view: "grid", scrollX: "gridScroll",scrollable: true, scrollY: "scrollVer"},
            { view: "scrollbar", id: "gridScroll", group: "horizontal" },
          ],
        },
        { resizer: true, width: 1 },
        {
          rows: [
            { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
            { view: "scrollbar", id: "scrollHor", group: "horizontal" },
          ],
        },
        { view: "scrollbar", id: "scrollVer" },
      ],
    }; */

    gantt.config.layout = {
      css: "gantt_container",
      cols: [
        {
          width: 700,
          min_width: 300,
          rows: [
            {view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer",},
            { view: "scrollbar", id: "gridScroll", group: "horizontal" },
          ],
        },
        { resizer: true, width: 1 },
        {
          rows: [
            { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
            { view: "scrollbar", id: "scrollHor", group: "horizontal" },
          ],
        },
        { view: "scrollbar", id: "scrollVer" },
      ],
    };

    gantt.init(ganttContainer.current)

    gantt.templates.tooltip_text = function (start, end, task) {
      let progressTooltip = Math.round(task.progress * 100) + "%";
      return (
        "<b>Task:</b> " +
        task.text +
        "</br>" +
        "<b>Progress:</b> " +
        progressTooltip +
        "<br/><b>Start date:</b> " +
        gantt.templates.tooltip_date_format(start) +
        "<br/><b>End date:</b> " +
        gantt.templates.tooltip_date_format(end)
      );
    };

    gantt.ext.tooltips.tooltipFor({
      selector: ".gantt_grid_head_add",
      html: function (event, domElement) {
        return t("schedule_tooltip_add");
        /*   var id = event.target.parentNode.attributes['task_id'].nodeValue;
              var task = gantt.getTask(id);
              return task.text; */
      },
    });

    const onRowDragEnd = gantt.attachEvent(
      "onRowDragEnd",
      function (id, target) {
        //update the order of tasks
        gantt.batchUpdate(function () {
          gantt.eachTask(function (task) {
            task.sort_order = task.$index + 1;
            gantt.updateTask(task.id);
          });
        });
      }
    );

    eventIDs.push(onRowDragEnd);

    /*  var dp = new gantt.dataProcessor(`${API_ROUTE}v2/schedule-tasks`) */
    var dp = gantt.createDataProcessor(function (entity, action, data, id) {
      /* console.log(data) */
      let taskInfohour = gantt.serverList("asigHours");
      let oneTaskInfoHour = taskInfohour?.filter((it) => {
        return it.IdActividad == data.id;
      });
      let ownerHourTask = data?.owner_id?.map((it) => {
        return {
          resource_id: it.resource_id,
          value: it.value,
          assignment_id: oneTaskInfoHour
            ?.filter((itx) => {
              return itx.IdRecurso == it.resource_id;
            })
            .map((itx3) => {
              return itx3.IdAsignacionHoras;
            })[0],
        };
      });
      /* console.log(taskInfohour)
             console.log(oneTaskInfoHour)
             console.log(ownerHourTask) */

      if (entity === "task") {
        let start = moment(data.start_date, "DD-MM-YYYY HH:mm");
        let end = moment(data.end_date, "DD-MM-YYYY HH:mm");
        if (start > end) {
          return;
        }

        data.projectUID = gantt.serverList("projectId")[0];
        data.key = data.id;
        data.parentId = data.parent;
        data.start = start.format();
        data.end = end.format();
        data.start_date = start.format();
        data.end_date = end.format();
        data.propietarios = ownerHourTask;
        data.owner_id = ownerHourTask;
        data.duration = data.duration / 8
        
        /*        data.plancost = (data.plancost) ? data.plancost : 0
                data.realcost = (data.realcost) ? data.realcost : 0 */

        switch (action) {
          case "create":
            console.log("creado");
            return axios
              .post(`${API_ROUTE}v2/schedule-tasks`, data, headersRequest())
              .then(function (response) {
                console.log("se creo");
                gantt.changeTaskId(data.id, response.data.TaskUID);

                props.updatePctProje(); //actualizar / avance
                gantt.refreshData();
              })
              .catch((err) => {
                alert(
                  "error al crear la actividad, por favor refrescar la página"
                );
              });
            break;
          case "update":
            if (data.start_date > data.end_date) {
              return;
            }

            if (data.target !== undefined) {
              if (data.target.indexOf("null") !== -1) {
                alert(
                  "error al actualizar la actividad, por favor refrescar la página"
                );
                return false;
              }
              axios.put(`${API_ROUTE}v2/schedule-tasks-move/${gantt.serverList("projectId")[0]}`,data,headersRequest())
                .then(() => {
                  gantt.refreshData();
                  props.updatePctProje();
                })
                .catch((err) => {
                  alert(
                    "error al actualizar la actividad, por favor refrescar la página"
                  );
                });
              /*  axios
                            .put(
                            `${API_ROUTE}v2/schedule-tasks/${data.id}`,
                            data,
                            headersRequest()
                            )
                            .then(() => {
                            props.updatePctProje()
                            gantt.refreshData();
                            
                            })
                            .catch((err) => {
                            alert("error al actualizar la actividad, por favor refrescar la página");
                            }); */
            } else {
              axios
                .put(
                  `${API_ROUTE}v2/schedule-tasks/${data.id}`,
                  data,
                  headersRequest()
                )
                .then(() => {
                  props.updatePctProje();
                  gantt.refreshData();
                })
                .catch((err) => {
                  alert(
                    "error al actualizar la actividad, por favor refrescar la página"
                  );
                });
              /*   
                        if(data.target.indexOf("null") !== -1)
                        {
                            alert("error al actualizar la actividad, por favor refrescar la página");
                            return false;
                        }
                        axios
                            .put(
                            `${API_ROUTE}v2/schedule-tasks-move/${gantt.serverList("projectId")[0]}`,
                            data,
                            headersRequest()
                            )
                            .then(() => {
                            gantt.refreshData();
                            props.updatePctProje() 
                            })
                            .catch((err) => {
                            alert("error al actualizar la actividad, por favor refrescar la página");
                            });
                          */
            }

            break;
          case "delete":
            axios
              .delete(
                `${API_ROUTE}v2/schedule-tasks/${data.id}`,
                headersRequest()
              )
              .then(() => {
                gantt.refreshData();
                props.updatePctProje();
              })
              .catch((err) => {
                alert(
                  "error al eliminar la actividad, por favor refrescar la página"
                );
              });
            break;
        }
      } else if (entity === "link") {
        switch (action) {
          case "create":
            if (data.id > 1000000) {
              axios
                .post(
                  `${API_ROUTE}schedule-tasks-dependencies/${
                    gantt.serverList("projectId")[0]
                  }`,
                  { predecessorId: data.source, successorId: data.target },
                  headersRequest()
                )
                .then(() => {
                  gantt.refreshData();
                })
                .catch((err) => {
                  alert(
                    "error al crear la relación, por favor refresca la página"
                  );
                });
            }
            break;
          case "delete":
            axios
              .delete(
                `${API_ROUTE}schedule-tasks-dependencies/${data.source}/${
                  data.target
                }/${gantt.serverList("projectId")[0]}`,
                headersRequest()
              )
              .then(() => {
                gantt.refreshData();
              })
              .catch((err) => {
                alert(
                  "error al eliminar la relación, por favor refresca la página"
                );
              });
            break;
        }
      }
    });
    dp.init(gantt);

    return () => {
      gantt.clearAll();
      dp.destructor();
      eventIDs.forEach((event) => gantt.detachEvent(event));
      eventIDs = [];
      const popups = document.querySelectorAll(".gantt-info");
      popups.forEach(function (el) {
        el.remove();
      });
      const tooltips = document.querySelectorAll(".gantt_tooltip");
      tooltips.forEach(function (el) {
        el.remove();
      });
    };
  }, []);

  const setLightBoxSections = () => {
    gantt.config.lightbox.sections = [
      {
        name: "description",
        label: "Actividad",
        height: 30,
        map_to: "text",
        type: "textarea",
        focus: true,
      },
      { name: "time", height: 35, map_to: "auto", type: "datepicker" },
      {
        name: "type",
        type:"typeselect",
        map_to:"type",
        height:30,
      },
  /*     {
        name: "type",
        height: 30,
        map_to: "type",
        type: "typeselect",
        options: [
          { key: "project", label: "Fase" },
          { key: "task", label: "Tarea" },
          { key: "milestone", label: "Hito" },
        ],
      }, */
      {
        name: "progress",
        height: 30,
        map_to: "progress",
        type: "progress_number",
      },
      {
        name: "owner",
        height: 150,
        map_to: "owner_id",
        type: "resources",
        default_value: 8,
        options: gantt.serverList("resourceList"),
      },
    ];
    gantt.locale.labels.section_type = t("schedule_gantt_type");
    gantt.locale.labels.section_description = t("schedule_gantt_activity");
    gantt.locale.labels.section_owner = t("schedule_gantt_resources");
    gantt.locale.labels.section_time = t("schedule_gantt_date");
    gantt.locale.labels["section_progress"] = t("schedule_gantt_%progress");
    gantt.locale.labels.type_project = "Fase"
    gantt.render();
  };

  //modal edit milestone
  const sectionForMilestonesProjects = () => {
    /* console.log(gantt.serverList("resourceList")) */
    gantt.config.lightbox["milestone" + "_sections"] = [
      {
        name: "description",
        label: "Actividad",
        height: 50,
        map_to: "text",
        type: "textarea",
        focus: true,
      },
      {
        name: "time",
        height: 35,
        map_to: "auto",
        type: "duration",
        single_date: true,
      },
      {
        name: "type",
        type:"typeselect",
        map_to:"type"
      },
   /*    {
        name: "type",
        height: 50,
        map_to: "type",
        type: "typeselect",
        options: [
          { key: "project", label: "Fase" },
          { key: "task", label: "Tarea" },
          { key: "milestone", label: "Hito" },
        ],
      }, */
      {
        name: "progress",
        height: 50,
        map_to: "progress",
        type: "select",
        options: [
          { key: "0", label: "Sin Iniciar" },
          { key: "0.1", label: "10%" },
          { key: "0.2", label: "20%" },
          { key: "0.3", label: "30%" },
          { key: "0.4", label: "40%" },
          { key: "0.5", label: "50%" },
          { key: "0.6", label: "60%" },
          { key: "0.7", label: "70%" },
          { key: "0.8", label: "80%" },
          { key: "0.9", label: "90%" },
          { key: "1", label: "Completa" },
        ],
      },

      {
        name: "owner",
        height: 120,
        map_to: "owner_id",
        type: "resources",
        default_value: 8,
        options: gantt.serverList("resourceList"),
      },
    ];
  };

  //modal edit project

  const sectionForProjects = () => {
    gantt.config.lightbox["project" + "_sections"] = [
      {
        name: "description",
        label: "Actividad",
        height: 50,
        map_to: "text",
        type: "textarea",
        focus: true,
      },
      {
        name: "time",
        height: 35,
        map_to: "auto",
        type: "duration",
        readonly: true,
      },
      //{name:"time", height:35, map_to:"auto", type:"datepicker"},
      {
        name: "type",
        type:"typeselect",
        map_to:"type",
        height:35,
      },
  /*     {
        name: "type",
        height: 50,
        map_to: "type",
        type: "typeselect",
        options: [
          { key: "project", label: "Fase" },
          { key: "task", label: "Tarea" },
          { key: "milestone", label: "Hito" },
        ],
      }, */
    ];
  };

  // confi columns
  const configColumns = () => {
    let textEditor = { type: "text", map_to: "text" };
    let dateEditor = {
      type: "date",
      map_to: "start_date",
      min: new Date(2018, 0, 1),
      max: new Date(2019, 0, 1),
    };
    let durationEditor = {
      type: "duration", // antes number
      map_to: "duration",
      formatter : autoFormatter, // sin formato
      min: 0,
      max: 1000,
    };

    let dayDurationEditor = { type :"duration", map_to : "duration", formatter: dayFormatter, min: 0 , max : 1000 }
    let hourDurationEditor = { type :"duration", map_to : "duration", formatter: hourFormatter, min: 0 , max : 1000 }

    var getInput = function (node) {
      return node.querySelector("input");
    };

    gantt.config.editor_types.AllCost = {
      show: (id, column, config, placeholder) => {
        let typeOfTask = gantt.getTaskType(gantt.getTask(id));
        let min = config.min || 0,
          max = config.max || 100;
        console.log(gantt.getTask(id));
        var html =
          "<div><input class='bg-light' disabled type='number' min='" +
          min +
          "' max='" +
          max +
          "' name='" +
          column.name +
          "'></div>";

        var html2 =
          "<div><input  type='number' min='" +
          min +
          "' max='" +
          max +
          "' name='" +
          column.name +
          "'></div>";

        placeholder.innerHTML = typeOfTask == "project" ? html : html2;
      },
      hide: () => {},
      set_value: function (value, id, column, node) {
        console.log(value);
        getInput(node).value = value;
      },
      get_value: function (id, column, node) {
        console.log(node);
        return getInput(node).value || 0;
      },
      is_changed: function (value, id, column, node) {
        var currentValue = this.get_value(id, column, node);
        return Number(value) !== Number(currentValue);
      },
      is_valid: function (value, id, column, node) {
        return !isNaN(parseInt(value, 10));
      },
      focus: function (node) {
        var input = getInput(node);
        if (!input) {
          return;
        }
        if (input.focus) {
          input.focus();
        }

        if (input.select) {
          input.select();
        }
      },
    };

    gantt.config.editor_types.AllCostReal = {
      show: (id, column, config, placeholder) => {
        let typeOfTask = gantt.getTaskType(gantt.getTask(id));
        let min = config.min || 0,
          max = config.max || 100;
        console.log(gantt.getTask(id));
        let taskInfoForCost = gantt.getTask(id);
        let taskInfoForCostReal = gantt.getTask(id)?.realcost;
        let taskInfoForCostPlan = gantt.getTask(id)?.plancost;
        let forColor = () => {
          if (taskInfoForCostReal > taskInfoForCostPlan) {
            return "bg-danger";
          } else if (taskInfoForCostReal > taskInfoForCostPlan) {
            return "bg-success";
          } else {
            return "";
          }
        };
        /* let colorForCost = (taskInfoForCostReal > taskInfoForCostPlan)? "bg-danger":"bg-success";  */

        var html =
          "<div><input class='bg-light' disabled type='number' min='" +
          min +
          "' max='" +
          max +
          "' name='" +
          column.name +
          "'></div>";

        var html2 =
          "<div><input  type='number' min='" +
          min +
          "' max='" +
          max +
          "' name='" +
          column.name +
          "'></div>";
        /* var html2 = `<div><input class="bg-danger" type='number' min= ${min} max= ${max} name=${column.name}></div>` */

        placeholder.innerHTML = typeOfTask == "project" ? html : html2;
      },
      hide: () => {},
      set_value: function (value, id, column, node) {
        console.log(value);
        getInput(node).value = value;
      },
      get_value: function (id, column, node) {
        console.log(node);
        return getInput(node).value || 0;
      },
      is_changed: function (value, id, column, node) {
        var currentValue = this.get_value(id, column, node);
        return Number(value) !== Number(currentValue);
      },
      is_valid: function (value, id, column, node) {
        return !isNaN(parseInt(value, 10));
      },
      focus: function (node) {
        var input = getInput(node);
        if (!input) {
          return;
        }
        if (input.focus) {
          input.focus();
        }

        if (input.select) {
          input.select();
        }
      },
    };

    /*  gantt.config.round_dnd_dates = false; */
    /* gantt.config.editor_types.dates_test = {
        show: function (id, column, config, placeholder) {
              var html = "<div><input type='date' name='" + column.name + "'></div>";
              placeholder.innerHTML = html;
        },
        hide: function () {
          gantt.render();
        },
      
        set_value: function (value, id, column, node) {
          var date_local_value = gantt.date.date_to_str("%d-%m-%Y")(value)
          node.firstChild.firstChild.value = date_local_value;
        },
      
        get_value: function (id, column, node) {
          var task = gantt.getTask(id);
          var node_value = node.firstChild.firstChild.value;
          node_value = node_value.replace('T', ' ')
          var new_value = gantt.date.str_to_date("%d-%m-%Y")(node_value)
          task.start_date = new_value;
          
        return task.start_date
        },
      
        is_changed: function (value, id, column, node) {
          return true;
        },
      
        is_valid: function (value, id, column, node) {
          return true;
        },
      
        save: function (id, column, node) {
        },
        focus: function (node) {
        }
      } */

    let planEditor = {
      type: "AllCost",
      map_to: "plancost",
      min: 0,
      max: 9999999999,
    };
    let realEditor = {
      type: "AllCostReal",
      map_to: "realcost",
      min: 0,
      max: 9999999999,
    };
    let textEditorColum = { type: "text", map_to: "text" };
    let start_dateEditor = { type: "date", map_to: "start_date" };
    let end_dateEditor = { type: "date", map_to: "end_date" };
    const rol = window.localStorage.getItem("rol");

    function byId(list, id) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].key == id) return list[i].label || "";
      }
      return "";
    }
    const confiColumnsME = [
      /*  { name: "add", align: "center", }, */
      {
        name: "wbs",
        label: "Wbs",
        width: "40",
        template: gantt.getWBSCode,
        resize: true,
        hide: showColumns.activeWbs,
      },
      { 
        name:"id", 
        label:"UID",
        align: "center",
        min_width: 300,
        resize: true,
        hide:showColumns.activeUnique, 
      },
      {
        name: "text",
        label: t("schedule_gantt_activity"),
        tree: true,
        min_width: 250,
        width: "*",
        resize: true,
        hide:showColumns.activeTask,
        editor: textEditorColum,
        template: (item) => {
          let normalText = `<b>${item.text}</b>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return item.text;
          }
        },
      },
      { name: "type", label: "Tipo", resize: false, hide: true },
      {
        name: "start_date",
        label: t("schedule_gantt_start"),
        align: "center",
        min_width: 100,
        resize: true,
        editor: start_dateEditor,
        hide:showColumns.activeStart,
        template: (item) => {
          let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
            item.start_date
          );
          return dateWithFormat;
        },
      },
      {
        name: "end_date",
        label: t("schedule_gantt_end"),
        align: "center",
        min_width: 100,
        resize: true,
        editor: end_dateEditor,
        hide:showColumns.activeEnd,
        template: (item) => {
          let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
            item.end_date
          );
          return dateWithFormat;
        },
      },
      {
        name: "duration",
        label: t("schedule_gantt_duration"),
        align: "center",
        resize: true,
        editor: durationEditor,
        hide:showColumns.activeDuration,
      },
      {
        name: "plancost",
        label: t("schedule_gantt_cost"),
        min_width: 90,
        align: "center",
        resize: true,
        hide:showColumns.activeCost,
        editor: planEditor,
        template: (item) => {
          let valuePlan = item.plancost;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });
          let plancost = formatterCOP.format(valuePlan);
          let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return plancost;
          }
        },
      },
      {
        name: "realcost",
        label: t("schedule_gantt_actualCost"),
        min_width: 90,
        align: "center",
        resize: true,
        editor: realEditor,
        hide:showColumns.activeCostReal,
        template: (item) => {
          let valueRealCost = item.realcost;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });

          let taskInfoForCost = gantt.getTask(item.id);
          let taskInfoForCostReal = gantt.getTask(item.id)?.realcost;
          let taskInfoForCostPlan = gantt.getTask(item.id)?.plancost;
          let forColor =
            taskInfoForCostReal > taskInfoForCostPlan ? "#F1948A" : "#ABEBC6";

          let realCost = formatterCOP.format(valueRealCost);
          let normalText = `<div style="background-color: ${forColor}" class="w-100"><b>${realCost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return normalText;
          }
        },
      },
      {
        name: "predecessor",
        label: t("schedule_gantt_predecessor"),
        min_width: 90,
        align: "center",
        resize: true,
        hide: true,
        template: function (item) {
          let formatter = gantt.ext.formatters.durationFormatter({
            enter: "day",
            store: "hour",
            format: "day",
            hoursPerDay: 24,
            hoursPerWeek: 40,
            daysPerMonth: 30,
            short: true,
          });
          let linksFormatter = gantt.ext.formatters.linkFormatter({
            durationFormatter: formatter,
          });

          let links = item.$target;
          let labels = [];
          for (let i = 0; i < links.length; i++) {
            let link = gantt.getLink(links[i]);
            if (
              !labels.find((x) => {
                return x == linksFormatter.format(link);
              })
            )
              labels.push(linksFormatter.format(link));
          }
          let predecessors = labels.join(", ");

          return predecessors;
        },
      },
      {
        name: "progress",
        label: t("schedule_gantt_progress"),
        min_width: 90,
        align: "center",
        hide:showColumns.activeReal,
        template: function (item) {
          if (item.progress >= 1) return t("schedule_gantt_completed");
          if (item.progress == 0) return t("schedule_gantt_tobestarted");
          return Math.round(item.progress * 100) + "%";
        },
      },
      {
        name: "planned",
        label: " % Plan.",
        min_width: 90,
        align: "center",
        hide:showColumns.activePlan,
        template: function (item) {
          console.log(item);
          if(item.planned){
            if (item.planned >= 100) return t("schedule_gantt_completed")
            if (item.planned == 0) return t("schedule_gantt_tobestarted");
            return (item.planned) + "%"
          }
          return t("schedule_gantt_completed")
        },
      },
      {
        name: "indicator",
        label: "Indica.",
        min_width: 110,
        align: "center",
        hide:showColumns.activeIndi,
        template: function (item) {
          console.log(item);

          if (item.type != "project") {
            const iconCritical =
              "<i class='fa fa-exclamation-circle text-danger'></i>";
            const iconCriticalExt =
              "<i class='fa fa-exclamation-circle text-dark'></i>";
            const iconModerate =
              "<i class='fa fa-exclamation-circle text-warning'></i>";
            const iconOntime =
              "<i class='fa fa-check-circle text-success'></i>";
            const selectState = (indicator) => {
             
              if (indicator == 1) {
                return iconOntime;
              } else if (indicator == 2) {
                return iconModerate;
              } else if (indicator == 3) {
                return iconCritical;
              }else {
                return iconCriticalExt;
              }
            };
            const state = selectState(item.indicator);
            const valueOfIndi = byId(
              gantt.serverList("indicator"),
              item.indicator
            );
            const textOfIndi = `<div class="text-center w-100">${state}<b class="ml-1">${valueOfIndi}</b></div>`;
            return textOfIndi;
          } else {
            return;
          }
        },
      },
      /*  { name: "owner", width: 80, align: "center", label: "owner", template: function (item) {
          console.log(item.owner_id.map((resp)=> resp.resource_id).join())
          return byId(gantt.serverList('resourceList'), item.owner_id.map((resp)=> resp.resource_id).join())
        }}, */
      // {
      //   name: "owner_id", label: "Recursos", width: "*", min_width: 300, align: "left", template: function (item) {
      //     return GetResourceNames(gantt.serverList("resourceList"), item.owner_id);
      //   }
      // },
    ];

   /*  let textFilter = ["<div class ='gantt-sub-header' >",
      "<div>Actividad  <input placeholder='Buscar Actividad...' data-text-filter style='height:20px' type='text' oninput='gantt.$doFilter(this.value)'></div>",
      "</div>"
    ].join("") */
    let textFilter = `Actividad <input class = ' form-control search-text-column' placeholder='Buscar Actividad...' data-text-filter  type='text' oninput='gantt.$doFilter(this.value)'>`
    const confiColumnsSTD = [
      {
        name:"N°",
        label:"N°",
        align: "center",
        min_width: 50,
        resize: true,
        template: (item) => {
          
          return item.$index  + 1
        },
      },
      { name: "add", align: "center" },
      {
        name:"id",
        label:"UID",
        align: "center",
        min_width: 300,
        resize: true,
        hide:showColumns.activeUnique,
      },

      {
        name: "wbs",
        label: "Wbs",
        width: "60",
        hide: showColumns.activeWbs,
        template: gantt.getWBSCode,
        resize: true,
      },
      {
        name: "text",
        label: textFilter,
        tree: true,
        min_width: 250,
        width: "*",
        resize: true,
        hide:showColumns.activeTask,
        editor: textEditorColum,
        template: (item) => {
          let normalText = `<b>${item.text}</b>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return item.text;
          }
        },
      },
      { name: "type", label: "Tipo", resize: false, hide: true },
      {
        name: "start_date",
        label: t("schedule_gantt_start"),
        align: "center",
        min_width: 100,
        resize: true,
        editor: start_dateEditor,
        hide: showColumns.activeStart,
        template: (item) => {
          let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
            item.start_date
          );
          return dateWithFormat;
        },
      },
      {
        name: "end_date",
        label: t("schedule_gantt_end"),
        align: "center",
        min_width: 100,
        resize: true,
        editor: end_dateEditor,
        hide:showColumns.activeEnd,
        template: (item) => {
          let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
            item.end_date
          );
          return dateWithFormat;
        },
      },
      {
        name: "duration",
        label: t("schedule_gantt_duration"),
        align: "center",
        resize: true,
        hide : true,
        with: 110,
        editor: durationEditor,
        template : (item)=> {
          return item.duration
        },
       /*  hide: showColumns.activeDuration, */
      },
     /*  {
        name: "duration",
        label:"Duración (d)",
        label: t("schedule_gantt_duration"),
        align: "center",
        resize: true,
        hide : false,
        hide : showColumns.active_duration_fci,
        with: 110,
        editor: durationEditor,
        template : (item)=> {
         
          return item.custom_duration
        },
        hide: showColumns.activeDuration,
      }, */


      {
        name :"dayDuration",
        label : "Duración(d)",
        resize : true,
        align : "center",
        editor : dayDurationEditor,
        with: 110,
        hide : showDuration,
        template : (task)=>{
          if(props.uid  == '7EAE00F6-6402-4D72-9492-150BD4596C9F' ){
            return task.custom_duration
          }else{ 
            return dayFormatter.format(task.duration)
          }
        }
      },
      {
        name :"hourDuration",
        label : "Duración(hrs)",
        resize : true,
        align : "center",
        editor : hourDurationEditor,
        with: 50,
        hide: !showDuration,
        template : (task)=>{

          if(props.uid  == '7EAE00F6-6402-4D72-9492-150BD4596C9F' ){
            return (task.custom_duration * 8).toFixed(2)
          }else{ 
            return hourFormatter.format(task.duration)
          }

        /*   return hourFormatter.format(task.duration) */
        }
      },
      {
        name: "plancost",
        label: t("schedule_gantt_cost"),
        min_width: 90,
        align: "center",
        resize: true,
        hide: showColumns.activeCost,
        editor: planEditor,
        template: (item) => {
          let valuePlan = item.plancost;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });
          let plancost = formatterCOP.format(valuePlan);
          let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return plancost;
          }
        },
      },
      {
        name: "realcost",
        label: t("schedule_gantt_actualCost"),
        min_width: 90,
        align: "center",
        resize: true,
        editor: realEditor,
        hide: showColumns.activeCostReal,
        template: (item) => {
          let valueRealCost = item.realcost;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });

          let taskInfoForCost = gantt.getTask(item.id);
          let taskInfoForCostReal = gantt.getTask(item.id)?.realcost;
          let taskInfoForCostPlan = gantt.getTask(item.id)?.plancost;
          let forColor =
            taskInfoForCostReal > taskInfoForCostPlan ? "#F1948A" : "#ABEBC6";

          let realCost = formatterCOP.format(valueRealCost);
          let normalText = `<div style="background-color: ${forColor}" class="w-100"><b>${realCost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return normalText;
          }
        },
      },
      {
        name: "predecessor",
        label: t("schedule_gantt_predecessor"),
        min_width: 90,
        align: "center",
        resize: true,
        hide: showColumns.activePrede,
        template: function (item) {
          let formatter = gantt.ext.formatters.durationFormatter({
            enter: "day",
            store: "hour",
            format: "day",
            hoursPerDay: 24,
            hoursPerWeek: 40,
            daysPerMonth: 30,
            short: true,
          });
          let linksFormatter = gantt.ext.formatters.linkFormatter({
            durationFormatter: formatter,
          });

          let links = item.$target;
          let labels = [];
          for (let i = 0; i < links.length; i++) {
            let link = gantt.getLink(links[i]);
            if (
              !labels.find((x) => {
                return x == linksFormatter.format(link);
              })
            )
              labels.push(linksFormatter.format(link));
          }
          let predecessors = labels.join(", ");

          return predecessors;
        },
      },
      {
        name: "successors",
        label:"Sucesora",
        resize: true,
        with:200,
        template : function (task){

          const successors = gantt.getLinks().filter(link => link.source === task.id)

          const successorNames = successors?.map((link)=>{
            const succesorTask = gantt.getTask(link.target)
            return succesorTask ? succesorTask.$wbs : "desconocida"
          })
          return successorNames.length > 0 ? successorNames.join(",") : ""
          
        }

      },
      {
        name: "progress",
        label: t("schedule_gantt_progress"),
        min_width: 90,
        align: "center",
        hide: showColumns.activeReal,
        template: function (item) {
          /* if (item.progress >= 1) return t("schedule_gantt_completed");
          if (item.progress == 0) return t("schedule_gantt_tobestarted"); */
          return Math.round(item.progress * 100) + "%";
        },
      },
      {
        name: "planned",
        label: " % Plan.",
        min_width: 90,
        align: "center",
        hide: showColumns.activePlan,
        template: function (item) {
          if (item.planned) {
            return `${item.planned}%`;
          } else {
            return 0;
          }
          /* if(item.planned){
            if (item.planned >= 100) return t("schedule_gantt_completed")
            if (item.planned == 0) return t("schedule_gantt_tobestarted");
            return (item.planned) + "%"
          } */
          /* return t("schedule_gantt_completed") */
        },
      },
      {
        name: "indicator",
        label: "Indica.",
        min_width: 110,
        align: "center",
        hide: showColumns.activeIndi,
        template: function (item) {

          if (item.type != "project") {
            const iconCritical =
              "<i class='fa fa-exclamation-circle text-danger'></i>";
            const iconCriticalExt =
              "<i class='fa fa-exclamation-circle text-dark'></i>";
            const iconModerate =
              "<i class='fa fa-exclamation-circle text-warning'></i>";
            const iconOntime =
              "<i class='fa fa-check-circle text-success'></i>";
            const selectState = (indicator) => {
              if (indicator == 1) {
                return iconOntime;
              } else if (indicator == 2) {
                return iconModerate;
              }else if (indicator == 3) {
                return iconCritical;
              }
               else {
                return iconCriticalExt;
              }
            };
            const state = selectState(item.indicator);
            const valueOfIndi = byId(
              gantt.serverList("indicator"),
              item.indicator
            );
            const textOfIndi = `<div class="text-center w-100">${state}<b class="ml-1">${valueOfIndi}</b></div>`;
            return textOfIndi;
          } else {
            return;
          }
        },
      },
      //lb_1
      { name: "start_baseline_date", label: "Inicio LB 1", align: "center", min_width: 100, readonly: true,  hide: showColumns.actLB_start_1, resize: true,
        template: (item) => {
          if (item.start_baseline_date) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.start_baseline_date
            );
            return dateWithFormat;
          } else {
            return "-";
          }
        },
      },
      {
        name: "end_baseline_date", label: "Fin LB 1 ", align: "center", min_width: 100, readonly: true, resize: true,
        hide: showColumns.actLB_end_1,
        template: (item) => {
          if (item.end_baseline_date) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.end_baseline_date
            );
            return `${dateWithFormat}`;
          } else {
            return "-";
          }
        },
      },
      {
        name: "durationLB_1",
        label: "Duración LB 1",
        align: "center",
        resize: true,
        hide: showColumns.actLB_duration_1,
        template: (item) => {
          if (item.durationLB_1) {
            return item.durationLB_1;
          } else {
            return 0;
          }
        },
      },
      {
        name: "costoLB1",
        label: "Costo LB 1",
        min_width: 100,
        align: "center",
        resize: true,
        hide: showColumns.actLB_cost_1,
        template: (item) => {
          if(item.costoLB1 != null){ 
          let valuePlan = item.costoLB1;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });
          let plancost = formatterCOP.format(valuePlan);
          let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return plancost;
          }
          }else {
            return 0
          }
        },
      },
      //lb_2
      {
        name: "start_baseline_date_2", label: "Inicio LB 2", align: "center", min_width: 100, readonly: true,  hide: showColumns.actLB_start_2,resize: true, template: (item) => {
          if (item.start_baseline_date_2) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.start_baseline_date_2
            );
            return dateWithFormat;
          } else {
            return "-";
          }
        },
      },
      {
        name: "end_baseline_date_2", label: "Fin LB 2", align: "center", min_width: 100, readonly: true, resize: true,
        hide: showColumns.actLB_end_2,
        template: (item) => {
          if (item.end_baseline_date_2) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.end_baseline_date_2
            );
            return `${dateWithFormat}`;
          } else {
            return "-";
          }
        },
      },
      {
        name: "durationLB_2",
        label: "Duración LB 2",
        align: "center",
        resize: true,
        hide: showColumns.actLB_duration_2,
        template: (item) => {
          if (item.durationLB_2) {
            return item.durationLB_2;
          } else {
            return 0;
          }
        },
      },
      {
        name: "costoLB2",
        label: "Costo LB 2",
        min_width: 100,
        align: "center",
        resize: true,
        hide: showColumns.actLB_cost_2,
        template: (item) => {
          if(item.costoLB2 != null){
          let valuePlan = item.costoLB2;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });
          let plancost = formatterCOP.format(valuePlan);
          let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return plancost;
          }
        }else{ return 0}
        },
      },
      //lb_3
      {
        name: "start_baseline_date_3",
        label: "Inicio LB 3",
        align: "center",
        min_width: 100,
        readonly: true,
        hide: showColumns.actLB_start_3,
        resize: true,
        template: (item) => {
          if (item.start_baseline_date_3) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.start_baseline_date_3
            );
            return dateWithFormat;
          } else {
            return "-";
          }
        },
      },
      {
        name: "end_baseline_date_3", label: "Fin LB 3", align: "center", min_width: 100, readonly: true, resize: true,
        hide: showColumns.actLB_end_3,
        template: (item) => {
          if (item.end_baseline_date_3) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.end_baseline_date_3
            );
            return `${dateWithFormat}`;
          } else {
            return "-";
          }
        },
      },
      {
        name: "durationLB_3",
        label: "Duración LB 3",
        align: "center",
        resize: true,
        hide: showColumns.actLB_duration_3,
        template: (item) => {
          if (item.durationLB_3) {
            return item.durationLB_3;
          } else {
            return 0;
          }
        },
      },
      {
        name: "costoLB3",
        label: "Costo LB 3",
        min_width: 100,
        align: "center",
        resize: true,
        hide: showColumns.actLB_cost_3,
        template: (item) => {
          if(item.costoLB3 != null){
          let valuePlan = item.costoLB3;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });
          let plancost = formatterCOP.format(valuePlan);
          let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return plancost;
          }
        }else { return 0}
        },
      },
      //lb_4
      {
        name: "start_baseline_date_4",
        label: "Inicio LB 4",
        align: "center",
        min_width: 100,
        readonly: true,
        hide: showColumns.actLB_start_4,
        resize: true,
        template: (item) => {
          if (item.start_baseline_date_4) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.start_baseline_date_4
            );
            return dateWithFormat;
          } else {
            return "-";
          }
        },
      },
      
      {
        name: "end_baseline_date_4", label: "Fin LB 4", align: "center", min_width: 100, readonly: true, resize: true,
        hide: showColumns.actLB_end_4,
        template: (item) => {
          if (item.end_baseline_date_4) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.end_baseline_date_4
            );
            return `${dateWithFormat}`;
          } else {
            return "-";
          }
        },
      },
      {
        name: "durationLB_4",
        label: "Duración LB 4",
        align: "center",
        resize: true,
        hide: showColumns.actLB_duration_4,
        template: (item) => {
          if (item.durationLB_4) {
            return item.durationLB_4;
          } else {
            return 0;
          }
        },
      },
      {
        name: "costoLB4",
        label: "Costo LB 4",
        min_width: 100,
        align: "center",
        resize: true,
        hide: showColumns.actLB_cost_4,
        template: (item) => {
          if(item.costoLB4 != null){
          let valuePlan = item.costoLB4;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
          });
          let plancost = formatterCOP.format(valuePlan);
          let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return plancost;
          }
        }else { return 0}
        },
      },
      //lb_5
      {
        name: "start_baseline_date_5",
        label: "Inicio LB 5",
        align: "center",
        min_width: 100,
        readonly: true,
        hide: showColumns.actLB_start_5,
        resize: true,
        template: (item) => {
          if (item.start_baseline_date_5) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.start_baseline_date_5
            );
            return dateWithFormat;
          } else {
            return "-";
          }
        },
      },
      {
        name: "end_baseline_date_5", label: "Fin LB 5", align: "center", min_width: 100, readonly: true, resize: true,
        hide: showColumns.actLB_end_5,
        template: (item) => {
          if (item.end_baseline_date_5) {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.end_baseline_date_5
            );
            return `${dateWithFormat}`;
          } else {
            return "-";
          }
        },
      },
      {
        name: "durationLB_5",
        label: "Duración LB 5",
        align: "center",
        resize: true,
        hide: showColumns.actLB_duration_5,
        template: (item) => {
          if (item.durationLB_5) {
            return item.durationLB_5;
          } else {
            return 0;
          }
        },
      },
      {
        name: "costoLB5",
        label: "Costo LB 5",
        min_width: 100,
        align: "center",
        resize: true,
        hide: showColumns.actLB_cost_5,
        template: (item) => {
          
          if(item.costoLB5 != null ){
          let valuePlan = item.costoLB5;
          const formatterCOP = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0, 
          });
          let plancost = formatterCOP.format(valuePlan);
          let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
          if (item.type == "project") {
            return normalText;
          } else {
            return plancost;
          }
          }else {return 0}
        },
      },
      // {
      //   name: "owner_id", label: "Recursos", width: "*", min_width: 300, align: "left", template: function (item) {
      //     return GetResourceNames(gantt.serverList("resourceList"), item.owner_id);
      //   }
      // },
    ];
    gantt.config.columns =
      rol == "Miembro de Equipo" ? confiColumnsME : confiColumnsSTD;

    gantt.templates.rightside_text = function (start, end, task) {
      /* console.log(task.owner_id, "text de recurso") */
      return getResourceNames(gantt.serverList("resourceList"), task.owner_id);
    };
    // Permitir reordenar verticamente dentro del mismo nivel
    /*    gantt.config.order_branch = "marker"; */
    /*  gantt.config.drag_progress = false; */
  };
  // setExclude dates
  const setCalender = (data) => {
    let excluded = [];
    let formatDate = gantt.date.str_to_date("%Y/%m/%d");
    for (let i = 0; i < data.length; i++) {
      let datex = formatDate(data[i]);
      excluded.push(datex);
      gantt.setWorkTime({
        date: datex,
        hours: false,
      });
    }
    gantt.serverList("excluded", excluded);
    setValiExcepcion(excluded)
    gantt.render();
  };
  // set Resources
  const setResour = (data) => {
    let resoList = data?.map((resource) => {
      return { key: resource.id, label: resource.text };
    });
    gantt.serverList("resourceList", resoList);
    setLightBoxSections();
    sectionForMilestonesProjects();
    /* sectionForProjects() */
    /* sectionForMilestonesProjects()
      sectionForProjects() */
  };
  const userid = window.localStorage.getItem("UID");
  const setTask = (data) => {
    //DUDA CON RESPECTO A LAS TAREAS QUE TIENEN CONEXION CON OTRAS
    const dataUniqueResource = data?.map((items)=> {
      return {
        ...items,
        resources: _.uniqBy(items.resources,"IdRecurso"), 
      }
    })
    
    const dataByRol = (data) => {
      const rol = window.localStorage.getItem("rol");
      if (rol == "Miembro de Equipo") {
        const dataByRolMember = data?.filter((task) => {
          if (task.tipo != "project") {
            return task?.resources
              .map((recur) => recur.IdRecurso)
              .includes(userid);
          } else {
            return task;
          }
        });
      /*   return dataByRolMember; */
        return data
      } else {
        return data;
      }
    };
    let datafilter = dataByRol(dataUniqueResource);
    let task = [];
    for (let row of datafilter) {
      let res = [];
      /*   if(row.recursos != null)
          {
              let resarr = row.recursos.split(',');
              for(let itm of resarr)
              {
              if(itm.length > 0)
              {
                  res.push({resource_id:itm, value:8});
              }
              }
          } */
      if (row.resources?.length > 0) {
        for (let itm of row.resources) {
          res.push({
            resource_id: itm.IdRecurso,
            value: itm.HorasAsignadas,
            asignacion_id: itm.IdAsignacion,
          });
        }
      }
      // Ahora, tanto el valor mínimo como el máximo están incluidos en el resultado.
      /* function getPriority (plan, real) {
        const realPor = real * 100
        const desvi = realPor - plan
        console.log(desvi)
        if(desvi >= 0){
          return 1
        }else if(desvi <= -1 && desvi >= -25 ){
          return 2
        }else {
          return 3
        }
      } */
      // criterios indicadores afinia
      function getIndicator (plan, real) {
        const realPor = real * 100
        const desvi = plan - realPor
        /* console.log(desvi) */
        if(desvi < 2 ){
          return 1
        }else if(desvi >= 2 && desvi < 5 ){
          return 2
        }else if(desvi >=5 && desvi < 10 ){
          return 3
        }
        else {
          return 4
        }
      }
      const readOnlyByRol = (data) => {
        if(data.tipo != "project"){
            const readByMember = data.recursos?.includes(userid)
            return !readByMember
        }else {
          return false
        }
      };
      const rolForRead = window.localStorage.getItem("rol");

      task.push({
        id: row.id,
        text: row.title,
        start_date: row.start,
        end_date: row.end,
        progress: row.progress,
        custom_duration : row.Duracion,

        start_baseline_date : (row.fechaInicioLB_1 != null)? new Date(row.fechaInicioLB_1) : null ,
        end_baseline_date: (row.fechaFinLB_1 != null)? new Date(row.fechaFinLB_1) : null ,
        modification_date_baseline : row.fechaModificacion_1 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_1) ) : null,
        baseline_number_1 : row.numeroLB_1,
        durationLB_1 : row.duracionLB_1,
        costoLB1 : row.costoLB_1,
        creation_dateLB1: row.fechaCreacionLB_1 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_1) ) : null ,

        start_baseline_date_2 : (row.fechaInicioLB_2 != null)? new Date(row.fechaInicioLB_2) : null ,
        end_baseline_date_2: (row.fechaFinLB_2 != null)? new Date(row.fechaFinLB_2) : null ,
        modification_date_baseline_2 : row.fechaModificacion_2 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_2) ) : null,
        baseline_number_2 : row.numeroLB_2,
        durationLB_2 : row.duracionLB_2,
        costoLB2 : row.costoLB_2,
        creation_dateLB2: row.fechaCreacionLB_2 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_2) ) : null,

        start_baseline_date_3 : (row.fechaInicioLB_3 != null)? new Date(row.fechaInicioLB_3) : null ,
        end_baseline_date_3: (row.fechaFinLB_3 != null)? new Date(row.fechaFinLB_3) : null ,
        modification_date_baseline_3 :row.fechaModificacion_3 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_3) ) : null ,
        baseline_number_3 : row.numeroLB_3,
        durationLB_3 : row.duracionLB_3,
        costoLB3 : row.costoLB_3,
        creation_dateLB3: row.fechaCreacionLB_3 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_3) ) : null,

        start_baseline_date_4 : (row.fechaInicioLB_4 != null)? new Date(row.fechaInicioLB_4) : null ,
        end_baseline_date_4: (row.fechaFinLB_4 != null)? new Date(row.fechaFinLB_4) : null ,
        modification_date_baseline_4 : row.fechaModificacion_4 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_4) ) : null ,
        baseline_number_4 : row.numeroLB_4,
        durationLB_4 : row.duracionLB_4,
        costoLB4 : row.costoLB_4,
        creation_dateLB4: row.fechaCreacionLB_4 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_5) ) : null,

        start_baseline_date_5 : (row.fechaInicioLB_5 != null)? new Date(row.fechaInicioLB_5) : null,
        end_baseline_date_5: (row.fechaFinLB_5 != null)? new Date(row.fechaFinLB_5) : null ,
        modification_date_baseline_5 : row.fechaModificacion_5 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_5) ) : null ,
        baseline_number_5 : row.numeroLB_5,
        durationLB_5 : row.duracionLB_5,
        costoLB5 : row.costoLB_5,
        creation_dateLB5: row.fechaCreacionLB_5 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_5) ) : null ,

        parent: row.parentId,
        plancost: row.cost,
        realcost: row.actualCost,
        type: row.tipo,
        readonly: (rolForRead == "Miembro de Equipo")? readOnlyByRol(row): false,
        open: true,
        owner_id: res,
        indicator: getIndicator(row.plannedProgress, row.progress),
        planned : row.plannedProgress
      });
    }
    let alltask = { data: task };
    gantt.parse(alltask);
    setTaskForBaseLine(task)
    gantt.render();
  };
  const setDependen = (data) => {
    let links = [];
    for (let lnk of data) {
      gantt.addLink({
        id: lnk.id,
        type: "0",
        source: lnk.predecessorId,
        target: lnk.successorId,
      });
    }
    gantt.render();
  };

  //other

  const zoomConfig = {
    levels: [
      {
        name: "day",
        scale_height: 27,
        min_column_width: 80,
        scales: [{ unit: "day", step: 1, format: "%d" }],
      },
      {
        name: "week",
        scale_height: 50,
        min_column_width: 50,
        scales: [
          {
            unit: "week",
            step: 1,
            format: function (date) {
              var dateToStr = gantt.date.date_to_str("%d %M");
              var endDate = gantt.date.add(date, -6, "day");
              var weekNum = gantt.date.date_to_str("%W")(date);
              return (
                "#" +
                weekNum +
                ", " +
                dateToStr(date) +
                " - " +
                dateToStr(endDate)
              );
            },
          },
          { unit: "day", step: 1, format: "%j" },
        ],
      },
      {
        name: "month",
        scale_height: 50,
        min_column_width: 120,
        scales: [
          { unit: "month", format: "%F, %Y" },
          { unit: "week", format: "Week #%W" },
        ],
      },
      {
        name: "quarter",
        height: 50,
        min_column_width: 90,
        scales: [
          { unit: "month", step: 1, format: "%M" },
          {
            unit: "quarter",
            step: 1,
            format: function (date) {
              var dateToStr = gantt.date.date_to_str("%M");
              var endDate = gantt.date.add(
                gantt.date.add(date, 3, "month"),
                -1,
                "day"
              );
              return dateToStr(date) + " - " + dateToStr(endDate);
            },
          },
        ],
      },
      {
        name: "year",
        scale_height: 50,
        min_column_width: 30,
        scales: [{ unit: "year", step: 1, format: "%Y" }],
      },
    ],
  };
  const getGanttData = () => {
    let getTasks = new Promise((resolve, rej) => {
      axios
        .get(`${API_ROUTE}v2/schedule-tasks/${props.uid}`, headersRequest())

        .then((res) => {
          /* console.log(res.data); */
          let processed = res.data.tasks.tasks.map((task) => {

            let startFormat = new Date(task.start)
            startFormat.setHours(startFormat.getHours() - 5)
            let startFormatFinally = startFormat.toISOString()

            let endFormat = new Date(task.end)
            endFormat.setHours(endFormat.getHours() - 5)
            let endFormatFinally = endFormat.toISOString()
            /* console.log(endFormatFinally,startFormatFinally) */
            return {
              ...task,
              start: new Date(task.start),
             /*  start : new Date(startFormatFinally), */
              end: new Date(task.end),
             /*  end: new Date (endFormatFinally), */
              parentId: !task.parentId ? 0 : task.parentId,
              cost: task.cost ? task.cost : 0,
              resources: res.data.tasks.hours.filter((resourhours) => {
                return resourhours.IdActividad == task.id;
              }),
            };
          });
          console.log("Tasks:");
          setAllAct(processed);
          setTask(processed);
          gantt.serverList("asigHours", res.data.tasks.hours);
          setDependen(res.data.links);

          resolve(processed);
        });
    });
    let getDependencies = new Promise((resolve, rej) => {
      axios
        .get(
          `${API_ROUTE}schedule-tasks-dependencies/${props.uid}`,
          headersRequest()
        )
        .then((res) => {
          console.log("Dependences:");
          /* setDependen(res.data)  */
          resolve(res.data);
        });
    });
    let getAssignments = new Promise((resolve, rej) => {
      axios
        .get(
          `${API_ROUTE}schedule-tasks-resources-assignments/${props.uid}`,
          headersRequest()
        )
        .then((res) => {
          resolve(res.data);
        });
    });
    let getExcludedDates = new Promise((resolve, rej) => {
      axios.get(`${API_ROUTE}excluded-dates`, headersRequest()).then((res) => {
        setCalender(res.data);
        resolve(res.data);
      });
    });
    let getResources = new Promise((resolve, rej) => {
      axios
        .get(`${API_ROUTE}company-resources`, headersRequest())
        .then((res) => {
          /* console.log(res.data) */
          setResour(res.data);
          resolve(res.data);
        });
    });

    Promise.all([
      getTasks,
      getDependencies,
      getAssignments,
      getExcludedDates,
      getResources,
    ]).then((values) => {
      excludedDates.current = values[3];
      companyResources.current = values[4];
      ReactDOM.unstable_batchedUpdates(() => {
        console.log("respuestas");
        /* setTasks(values[0]); */
      });
    });
  };

  const getGanttDataTasks = () => {
    let getTasks = new Promise((resolve, rej) => {
      axios
        .get(`${API_ROUTE}v2/schedule-tasks/${props.uid}`, headersRequest())

        .then((res) => {
          console.log(res.data);
          let processed = res.data.tasks.tasks.map((task) => {
            return {
              ...task,
              start: new Date(task.start),
              end: new Date(task.end),
              parentId: !task.parentId ? 0 : task.parentId,
              cost: task.cost ? task.cost : 0,
              resources: res.data.tasks.hours.filter((resourhours) => {
                return resourhours.IdActividad == task.id;
              }),
            };
          });
          console.log("Tasks:");
          setAllAct(processed);
          setTask(processed);
          gantt.serverList("asigHours", res.data.tasks.hours);
          setDependen(res.data.links);

          resolve(processed);
        });
    });
   
    Promise.all([
      getTasks,
    ]).then((values) => {
      ReactDOM.unstable_batchedUpdates(() => {
        console.log("respuestas");
        /* setTasks(values[0]); */
      });
    });
  };
  const handleCheck = (setState) =>{
    setState((value)=>(value = !value))
  }



  //botones panel //
  const btnUndo = () => {
    gantt.undo();
  };
  const btnRedo = () => {
    gantt.redo();
  };
  const zoomIn = () => {
    gantt.ext.zoom.zoomIn();
  };

  const zoomInNew = () => {
    gantt.ext.zoom.zoomIn();
  };

  const zoomOut = () => {
    gantt.ext.zoom.zoomOut();
  };
  const expandAllTask = () => {
    gantt.batchUpdate(function () {
      gantt.eachTask(function (task) {
        gantt.open(task.id);
      });
    });
  };

  const collapseAllTask = () => {
    gantt.batchUpdate(function () {
      gantt.eachTask(function (task) {
        gantt.close(task.id);
      });
    });
  };

  const updateCriticalPath = (e) => {
    e.target.enabled = !e.target.enabled;
    if (e.target.enabled) {
      e.target.innerHTML = t("schedule_gantt_showcp");
      gantt.config.highlight_critical_path = true;
    } else {
      e.target.innerHTML = t("schedule_gantt_hidecp");
      gantt.config.highlight_critical_path = false;
    }
    gantt.render();
  };

  const handleExportMSP = (type) => {
    if (type === "MSproject") {
      gantt.exportToMSProject({
        skip_circular_links: false,
        server: "https://export.dhtmlx.com/msproject/",
      });
    } else if (type === "Excel") {
      gantt.exportToExcel({
        visual: true,
      });
    } else if (type === "PDF") {
      gantt.exportToPDF({
        raw: true,
      });
    } else {
      return;
    }
  };
  const handleImportMSP = () => {
    console.log("entrando al submit import");
  };
  function upload(file) {
    gantt.importFromMSProject({
      data: file,
      callback: function (project) {
        if (project) {
          gantt.clearAll();
          // if (project.config.duration_unit) {
          //     gantt.config.duration_unit = project.config.duration_unit;
          // }
          console.log("import: ", project);
          project.data.data.forEach(function (task) {
            task.end_date = task.$raw.Finish;
          });
          console.log(project.data);
          gantt.parse(project.data);
        }
      },
    });
  }

  function uploadFile() {
    const fileInput = document.getElementById("mspFile");
    // console.log('uploadFile: ', fileInput.files[0]);
    console.log(fileInput);
    upload(fileInput.files[0]);
  }
  //full screen
  gantt.attachEvent("onTemplatesReady", function () {
    var toggle = document.createElement("i");
    toggle.className = "fa fa-expand gantt-fullscreen";
    gantt.toggleIcon = toggle;
    gantt.$container.appendChild(toggle);
    toggle.onclick = function () {
      gantt.ext.fullscreen.toggle();
    };
  });

  gantt.attachEvent("onExpand", function () {
    var icon = gantt.toggleIcon;
    if (icon) {
      icon.className = icon.className.replace("fa-expand", "fa-compress");
    }
  });

  gantt.attachEvent("onCollapse", function () {
    var icon = gantt.toggleIcon;
    if (icon) {
      icon.className = icon.className.replace("fa-compress", "fa-expand");
    }
  });
  // mostrar colores en las filas y columna de progreso ambos grid //
  gantt.templates.grid_row_class = function (start_date, end_date, item) {
    /* console.log(item)
        if(item.type == "project") return "text-bold" */
    if(item.readonly){
      return "gray"
    }else {
      if (item.progress == 0) return "red";
      if (item.progress > 0 && item.progress <= 0.99) return "blue";
      if (item.progress >= 1) return "green";
    }
    
  };

  gantt.templates.task_row_class = function (start_date, end_date, item) {
    if(item.readonly){
      return "gray"
    }else {
      if (item.progress == 0) return "red";
      if (item.progress > 0 && item.progress <= 0.99) return "blue";
      if (item.progress >= 1) return "green";
    }
  };

  gantt.templates.task_class = function (start, end, task) {
    if (task.type == gantt.config.types.project)
      return "hide_project_progress_drag";
  };

  gantt.templates.task_class = function (start, end, task) {
		if (task.end_baseline_date) {
			var classes = ['has-baseline'];
			if (end.getTime() > task.end_baseline_date.getTime()) {
				classes.push('overdue');
			}
			return classes.join(' ');
		}
	};

  gantt.templates.grid_header_class = function (columnName, column) {
    if (columnName === "add") return "border-add";
  };

  //progreso manual // agregar useEffect
  gantt.form_blocks["progress_number"] = {
    render: function (sns) {
      return (
        "<div class='gantt_wrap_section' style='height:30px;'>" +
        "<input style='width:50px; margin-left:10px;' class='progress_number_c' type='number' min='0' max='100'>" +
        "</div>"
      );
    },
    set_value: function (node, value, task) {
      let decimalValue = Math.round(value * 100);
      node.querySelector(".progress_number_c").value = decimalValue || 0;
      // node.querySelector(".progress_number_c").value = Math.round( value * 100 ) || 0 ;
      //node.querySelector(".editor_holders").value = task.users || "";
      //Math.round(task.progress*100)
    },
    get_value: function (node, task) {
      // console.log(node.querySelector(".progress_number_c").value)
      // task.users = node.querySelector(".editor_holders").value;
      //return node.querySelector(".editor_description").value;
      return node.querySelector(".progress_number_c").value / 100;
    },
    focus: function (node) {
      var a = node.querySelector(".progress_number_c");
      a.select();
      a.focus();
    },
  };


  //progreso en fases rollup
  useEffect(() => {
    (function dynamicProgress() {
      function calculateSummaryProgress(task) {
        if (task.type != gantt.config.types.project) return task.progress;
        var totalToDo = 0;
        var totalDone = 0;
        gantt.eachTask(function (child) {
          if (child.type != gantt.config.types.project) {
            totalToDo += child.duration;
            totalDone += (child.progress || 0) * child.duration;
          }
        }, task.id);
        if (!totalToDo) return 0;
        else return totalDone / totalToDo;
      }

      function calculateSummaryProgressPlan(task) {
  
        if (task.type != gantt.config.types.project) return task.planned;
        var totalToDoPlan = 0;
        var totalDonePlan = 0;
        gantt.eachTask(function (child) {
          if (child.type != gantt.config.types.project) {
           
            totalToDoPlan += child.duration;
            totalDonePlan += (child.planned || 0) * child.duration;
          
          }
        }, task.id);
        if (!totalToDoPlan) return 0;
        else return totalDonePlan / totalToDoPlan;
      }

      function refreshSummaryProgress(id, submit) {
        if (!gantt.isTaskExists(id)) return;

        var task = gantt.getTask(id);
        var newProgress = calculateSummaryProgress(task);
       /*  var newPlanProgress = calculateSummaryProgressPlan(task) */ 
        if (newProgress !== task.progress) {
          task.progress = newProgress;

          if (!submit) {
            gantt.refreshTask(id);
          } else {
            gantt.updateTask(id);
          }
        }
       /* 
        if (newPlanProgress !== task.planned) {
          task.planned = newPlanProgress;
          
          if (!submit) {
            gantt.refreshTask(id);
          } else {
            gantt.updateTask(id);
          }
        } */

        if (!submit && gantt.getParent(id) !== gantt.config.root_id) {
          refreshSummaryProgress(gantt.getParent(id), submit);
        }
      }

      gantt.attachEvent("onParse", function () {
        gantt.eachTask(function (task) {
          task.progress = calculateSummaryProgress(task);
        /*   task.planned = calculateSummaryProgressPlan(task) */
        });
      });

      gantt.attachEvent("onAfterTaskUpdate", function (id) {
        refreshSummaryProgress(gantt.getParent(id), true);
      });

      gantt.attachEvent("onTaskDrag", function (id) {
        refreshSummaryProgress(gantt.getParent(id), false);
      });
      gantt.attachEvent("onAfterTaskAdd", function (id) {
        refreshSummaryProgress(gantt.getParent(id), true);
      });

      (function () {
        var idParentBeforeDeleteTask = 0;
        gantt.attachEvent("onBeforeTaskDelete", function (id) {
          idParentBeforeDeleteTask = gantt.getParent(id);
        });
        gantt.attachEvent("onAfterTaskDelete", function () {
          refreshSummaryProgress(idParentBeforeDeleteTask, true);
        });
      })();
    })();
  }, []);
  //calculo costo planificado
  useEffect(() => {
    (function dynamicPlanCost() {
      function calculateSymmaryPlanCost(task) {
        if (task.type != gantt.config.types.project) return task.plancost;
        // var totalToDo = 0;
        var totalPlanCostDone = 0;
        gantt.eachTask(function (child) {
          if (child.type != gantt.config.types.project) {
            // totalToDo += child.duration;
            //totalDone += (child.progress || 0) * child.duration;
            var converToNumber = parseFloat(child.plancost);
            totalPlanCostDone += converToNumber;
          }
        }, task.id);

        if (!totalPlanCostDone) return 0;
        else return totalPlanCostDone;
      }
      function refreshSummaryPlanCost(id, submit) {
        if (!gantt.isTaskExists(id)) return;

        var task = gantt.getTask(id);

        var newPlanCost = calculateSymmaryPlanCost(task);

        if (newPlanCost != task.plancost) {
          task.plancost = newPlanCost;

          if (!submit) {
            gantt.refreshTask(id);
          } else {
            gantt.updateTask(id);
          }
        }

        if (!submit && gantt.getParent(id) !== gantt.config.root_id) {
          refreshSummaryPlanCost(gantt.getParent(id), submit);
        }
      }
      gantt.attachEvent("onParse", function () {
        gantt.eachTask(function (task) {
          task.plancost = calculateSymmaryPlanCost(task);
        });
      });

      gantt.attachEvent("onAfterTaskUpdate", function (id) {
        refreshSummaryPlanCost(gantt.getParent(id), true);
      });

      gantt.attachEvent("onAfterTaskAdd", function (id) {
        refreshSummaryPlanCost(gantt.getParent(id), true);
      });

      (function () {
        var idParentBeforeDeleteTask = 0;
        gantt.attachEvent("onBeforeTaskDelete", function (id) {
          idParentBeforeDeleteTask = gantt.getParent(id);
        });
        gantt.attachEvent("onAfterTaskDelete", function () {
          refreshSummaryPlanCost(idParentBeforeDeleteTask, true);
        });
      })();
    })();
  }, []);
  // calculo costo real
  useEffect(() => {
    (function dynamicRealCost() {
      function calculateSymmaryRealCost(task) {
        if (task.type != gantt.config.types.project) return task.realcost;
        // var totalToDo = 0;
        var totalRealCostDone = 0;
        gantt.eachTask(function (child) {
          if (child.type != gantt.config.types.project) {
            //totalToDo += child.duration;
            //totalDone += (child.progress || 0) * child.duration;
            var converToNumber = parseFloat(child.realcost);
            totalRealCostDone += converToNumber;
          }
        }, task.id);

        if (!totalRealCostDone) return 0;
        else return totalRealCostDone;
      }
      function refreshSummaryRealCost(id, submit) {
        if (!gantt.isTaskExists(id)) return;

        var task = gantt.getTask(id);

        var newRealCost = calculateSymmaryRealCost(task);

        if (newRealCost != task.realcost) {
          task.realcost = newRealCost;

          if (!submit) {
            gantt.refreshTask(id);
          } else {
            gantt.updateTask(id);
          }
        }

        if (!submit && gantt.getParent(id) !== gantt.config.root_id) {
          refreshSummaryRealCost(gantt.getParent(id), submit);
        }
      }
      gantt.attachEvent("onParse", function () {
        gantt.eachTask(function (task) {
          task.realcost = calculateSymmaryRealCost(task);
        });
      });

      gantt.attachEvent("onAfterTaskUpdate", function (id) {
        refreshSummaryRealCost(gantt.getParent(id), true);
      });

      gantt.attachEvent("onAfterTaskAdd", function (id) {
        refreshSummaryRealCost(gantt.getParent(id), true);
      });

      (function () {
        var idParentBeforeDeleteTask = 0;
        gantt.attachEvent("onBeforeTaskDelete", function (id) {
          idParentBeforeDeleteTask = gantt.getParent(id);
        });
        gantt.attachEvent("onAfterTaskDelete", function () {
          refreshSummaryRealCost(idParentBeforeDeleteTask, true);
        });
      })();
    })();
  }, []);
  //views bt grups
  function showGroups(listname) {
    if (listname) {
      gantt.groupBy({
        groups: gantt.serverList(listname),
        relation_property: listname,
        group_id: "key",
        group_text: "label",
      });
      gantt.sort("start_date");
    } else {
      gantt.groupBy(false);
    }
  }
  // view duration btm
  const showDurationBtn =(type)=>{
    if(type == "days"){
      setShowDuration(true)
    }
    else{
      setShowDuration(false)
    }

  }
 /*Codigo datepicker */

 const [taskForDatePicker,setTaskForDatePicker]  = useState(null)

 useEffect(() => {

 /*  gantt.attachEvent("onBeforeLightbox", (id)=>{
    const taskDate = gantt.getTask(id)
    setTaskForDatePicker(taskDate)
    return true;
  })

  gantt.attachEvent("onLightboxCancel", ()=>{
    const taskToclear = {
      start_date : null,end_date: null
    }
    setTaskForDatePicker(taskToclear)
  }) */

  gantt.form_blocks["datepicker"] = {
    render: function (sns) {
      return "<div id='datepicker_block'></div>";
    },
    set_value: function (node, value, task, section) {
     /*  ReactDOM.createRoot( document.getElementById("datepicker_block")).render(
        <DatePickerBlock
          task={task}
          ganttMet={gantt}
          excluded ={excludedDates.current}
        />,

      ) */
     /*  setTaskForDatePicker(task) */
      ReactDOM.render(
        <DatePickerBlock
          task={task}
          ganttMet={gantt}
          excluded = {excludedDates.current}
        />,
        document.getElementById("datepicker_block")
      );
    },
    get_value: function (node, task, section) {
      console.log(task)

      task.duration = gantt.calculateDuration( task.start_date, task.end_date)
      // La duración se calcula automáticamente al actualizar las fechas
    },
    focus: function (node) {},
  }
 
  ;
}, []);

 

//add readonly input 

/* useEffect(()=>{
  console.log("se renderizan los nuevos inputs")
  gantt.form_blocks["template"] = {
    render: (sns)=>{
      return "<div id='modal_btns_duration_block'></div>"
      return "<div class='gant_cal_ltext'><input type='text' readonly='true'></div>"
    },
    set_value: (node,value,task,section)=>{
      ReactDOM.render(
        <BtnDurationModal 
        task={task}
        ganttMet={gantt}
        section = {section}
        />, 
        document.getElementById("modal_btns_duration_block")
      )
      if(section.name === "duration_in_days"){
        const durationInDays = gantt.calculateDuration(task.start_date, task.end_date, "hours") / 8;
        node.querySelector('input').value = `${durationInDays} días`
      }else if (section.name === "duration_in_hours") {
        const durationInHours = gantt.calculateDuration(task.start_date, task.end_date, "hours");
        node.querySelector('input').value = `${durationInHours} horas`
      }
    },
    get_value : (node,value, task, section)=>{

    },
    focus:(node)=>{}
  }
},[]) */
  
/*   useEffect(()=>{
    configColumns();
    gantt.render()
  },[activeWbs,activeTask,activeStart,activeEnd,activeDuration,activeCost,activeCostReal,activePrede,activeReal,activePlan,activeIndi, activeUnique,actLB_start_1,actLB_end_1,actLB_duration_1,actLB_cost_1,actLB_start_2,actLB_end_2,actLB_duration_2,actLB_cost_2,actLB_start_3,actLB_end_3,actLB_duration_3,actLB_cost_3,actLB_start_4,actLB_end_4,actLB_duration_4,actLB_cost_4,actLB_start_5,actLB_end_5,actLB_duration_5,actLB_cost_5]) */
useEffect(()=>{
  configColumns();
  gantt.render()
},[showColumns , showDuration])
  // adding baseline display
 const handleSetBaseline = (e)=>{

  if(savebaseline?.baseline.length > 0 ){

    const dataBaselineToSave = {
      IdProject : uid,
      baseline_number: Number(savebaseline?.baseline.split('_')[1]),
      baseline_action: "setting",
      tasksForBaseline :taskForBaseline,
      new : true
    }
    const baselineNumber = savebaseline?.baseline.split('_')[1]

    const success = () => {
      swal(
        "¡Hecho!",
        `Línea Base ${baselineNumber} Establecida`,
        "success"
      ).then((val) => {
        getGanttDataTasks()
      });
    };
    saveData("schedule-tasks-baseline-create", dataBaselineToSave, success)
    .then((val)=>{
      setSaveBaseline({baseline:""})
      setShowBaseLine(false)
    });
   
  }else {
    setSaveBaseline({baseline:""})
    setShowBaseLine(false)
  }
  
 }

 const handleRemoveBaseline = (e)=>{
 
  if(savebaseline?.baseline.length > 0 ){
    const baselineNumber = savebaseline?.baseline.split('_')[1]
    const success = () => {
      swal(
        "¡Hecho!",
        `Línea Base ${baselineNumber} Removida`,
        "success"
      ).then((val) => {
        console.log("trae las tareas")
        getGanttDataTasks()
      });
    };
    swal({
      title: "¿Eliminar Línea Base?",
      text: "Eliminara la línea base de todas las actividades",
      icon: "warning",
      buttons: true,
      buttons: ["cancel", "Si, Eliminar"],
      dangerMode: true 
    })
    .then((willDelete) => {
      console.log(willDelete)
      if (willDelete) {
        for(let task of taskForBaseline) {
          removeDataBaseline("schedule-tasks-baseline-remove", task.id, baselineNumber,uid)
        }
        success()
      }else
      {
        swal("Cancelado","La operación ha sido cancelada","info");

      }
    })
    /* .then((willDelete2)=>{
      console.log(willDelete2)
      if(!willDelete2){
        success()
        
      }else{
        swal("Cancelado","La operación ha sido cancelada","info");
      }
    }
    ); */
    setSaveBaseline({baseline:""})
    setShowBaseLine(false)
  }else {
    setSaveBaseline({baseline:""})
    setShowBaseLine(false)
  }
  
 }
  
  const saveCopyBaseline = (from,to,data)=>{

    axios.post(`${API_ROUTE}schedule-tasks-baseline-copy/${from}/${to}`, data, headersRequest())
    .then(function (response) {
      gantt.refreshData();
    })
    .catch((err) => {
      alert(
        "error al copiar la linea base, por favor refrescar la página"
      );
    });
  } 

  const handleCopyBaseline = ()=>{
    const fromBaseline =savebaselineCopyFrom?.copy_baseline_from.split('_')[1]
    const toBaseline = savebaselineCopyTo?.copy_baseline_to.split('_')[1]

     if( (fromBaseline && toBaseline) && (fromBaseline != toBaseline ) ){
      const success = () => {
        swal(
          "¡Hecho!",
          `Línea Base ${fromBaseline} Copiada en ${toBaseline}`,
          "success"
        ).then((val) => {
          getGanttDataTasks()
          setShowBaseLine(false)
          
        });
      };
      swal({
        title: "¿Copiar Línea Base?",
        text: `Los valores de la línea base ${fromBaseline}, serán copiado en la linea base ${toBaseline} y el cambio no podra ser revertido`,
        icon: "warning",
        buttons: true,
        buttons: ["cancel", "Si, Copiar"],
        dangerMode: true 
      })
      .then((willDelete) => {
        if (willDelete) {
          let data = {uid:uid}
          saveCopyBaseline(fromBaseline,toBaseline,data)
          success()
        }
        else{
          swal("Cancelado","La operación ha sido cancelada","info");
        }
      })

     }else if ((fromBaseline && toBaseline) && (fromBaseline == toBaseline )){
      swal("La operación ha sido cancelada","Las lineas bases deben ser diferentes","info")
     }else {
      console.log("ninguno existe")
     }
  }

  useEffect(()=> {
    switch (showBaselineGantt.showbaseline) {
      case "showbaseline_1":
        gantt.removeTaskLayer(2222)
        gantt.removeTaskLayer(3333)
        gantt.removeTaskLayer(4444)
        gantt.removeTaskLayer(5555)
      gantt.addTaskLayer({
          renderer: {
            render: function draw_planned(task) {
              if (task.start_baseline_date && task.end_baseline_date ) {
                var sizes = gantt.getTaskPosition(task, task.start_baseline_date, task.end_baseline_date);
                var el = document.createElement('div');
                el.className = 'baseline';
                el.style.left = sizes.left + 'px';
                el.style.width = sizes.width + 'px';
                el.style.top = sizes.top + gantt.config.bar_height + 13 + 'px';
                return el;
              }
              return false;
            },
            // define getRectangle in order to hook layer with the smart rendering
            getRectangle: function(task, view){
              if (task.start_baseline_date && task.end_baseline_date) {
                return gantt.getTaskPosition(task, task.start_baseline_date, task.end_baseline_date);
              }
              return null;
            }
          },
          id: 1111
        }) ;

        gantt.attachEvent("onTaskLoading", function (task) {
          task.start_baseline_date = gantt.date.parseDate(task.start_baseline_date, "xml_date");
          task.end_baseline_date = gantt.date.parseDate(task.end_baseline_date, "xml_date");
          return true;
      
        });
    
        gantt.templates.rightside_text = function (start, end, task) {
          if (task.end_baseline_date) {
            if (end.getTime() > task.end_baseline_date.getTime()) {
              var overdue = Math.ceil(Math.abs((end.getTime() - task.end_baseline_date.getTime()) / (24 * 60 * 60 * 1000)));
              var text = "<b>Overdue_1: " + overdue + " days</b>";
              return text;
            }
          }
        };
        gantt.refreshData();
        gantt.render();
        break;
      case "showbaseline_2":
        gantt.removeTaskLayer(1111)
        gantt.removeTaskLayer(3333)
        gantt.removeTaskLayer(4444)
        gantt.removeTaskLayer(5555)

        gantt.addTaskLayer({
          renderer: {
            render: function draw_planned(task) {
              if (task.start_baseline_date_2 && task.end_baseline_date_2 ) {
                var sizes = gantt.getTaskPosition(task, task.start_baseline_date_2, task.end_baseline_date_2);
                var el = document.createElement('div');
                el.className = 'baseline_2';
                el.style.left = sizes.left + 'px';
                el.style.width = sizes.width + 'px';
                el.style.top = sizes.top + gantt.config.bar_height + 13 + 'px';
                return el;
              }
              return false;
            },
            // define getRectangle in order to hook layer with the smart rendering
            getRectangle: function(task, view){
              if (task.start_baseline_date_2 && task.end_baseline_date_2) {
                return gantt.getTaskPosition(task, task.start_baseline_date_2, task.end_baseline_date_2);
              }
              return null;
            }
          },
          id: 2222
        });
      
        // end  adding baseline display
        gantt.attachEvent("onTaskLoading", function (task) {
          task.start_baseline_date_2 = gantt.date.parseDate(task.start_baseline_date_2, "xml_date");
          task.end_baseline_date_2 = gantt.date.parseDate(task.end_baseline_date_2, "xml_date");
          return true;
      
        });
    
        gantt.templates.rightside_text = function (start, end, task) {
          console.log("entra al texto del 2")
          if (task.end_baseline_date_2) {
            if (end.getTime() > task.end_baseline_date_2.getTime()) {
              var overdue = Math.ceil(Math.abs((end.getTime() - task.end_baseline_date_2.getTime()) / (24 * 60 * 60 * 1000)));
              var text = "<b>Overdue_2: " + overdue + " days</b>";
              console.log(text);
              return text;
            }
          }
        };
        gantt.refreshData();
        gantt.render();
      break;

      case "showbaseline_3":
        gantt.removeTaskLayer(1111)
        gantt.removeTaskLayer(2222)
        gantt.removeTaskLayer(4444)
        gantt.removeTaskLayer(5555)
        gantt.addTaskLayer({
          renderer: {
            render: function draw_planned(task) {
              if (task.start_baseline_date_3 && task.end_baseline_date_3 ) {
                var sizes = gantt.getTaskPosition(task, task.start_baseline_date_3, task.end_baseline_date_3);
                var el = document.createElement('div');
                el.className = 'baseline_3';
                el.style.left = sizes.left + 'px';
                el.style.width = sizes.width + 'px';
                el.style.top = sizes.top + gantt.config.bar_height + 13 + 'px';
                return el;
              }
              return false;
              },
              // define getRectangle in order to hook layer with the smart rendering
              getRectangle: function(task, view){
                if (task.start_baseline_date_3 && task.end_baseline_date_3) {
                  return gantt.getTaskPosition(task, task.start_baseline_date_3, task.end_baseline_date_3);
                }
                return null;
              }
          },
          id: 3333
        });
      
        // end  adding baseline display
        gantt.attachEvent("onTaskLoading", function (task) {
          task.start_baseline_date_3 = gantt.date.parseDate(task.start_baseline_date_3, "xml_date");
          task.end_baseline_date_3 = gantt.date.parseDate(task.end_baseline_date_3, "xml_date");
          return true;
      
        });
    
        gantt.templates.rightside_text = function (start, end, task) {
          if (task.end_baseline_date_3) {
            if (end.getTime() > task.end_baseline_date_3.getTime()) {
              var overdue = Math.ceil(Math.abs((end.getTime() - task.end_baseline_date_3.getTime()) / (24 * 60 * 60 * 1000)));
              var text = "<b>Overdue_3: " + overdue + " days</b>";
              return text;
            }
          }
        };
        
        gantt.refreshData();
        gantt.render();
      break;

      case "showbaseline_4":
        gantt.removeTaskLayer(1111)
        gantt.removeTaskLayer(2222)
        gantt.removeTaskLayer(3333)
        gantt.removeTaskLayer(5555)

        gantt.addTaskLayer({
          renderer: {
            render: function draw_planned(task) {
              if (task.start_baseline_date_4 && task.end_baseline_date_4 ) {
                var sizes = gantt.getTaskPosition(task, task.start_baseline_date_4, task.end_baseline_date_4);
                var el = document.createElement('div');
                el.className = 'baseline_4';
                el.style.left = sizes.left + 'px';
                el.style.width = sizes.width + 'px';
                el.style.top = sizes.top + gantt.config.bar_height + 13 + 'px';
                return el;
              }
              return false;
            },
            // define getRectangle in order to hook layer with the smart rendering
            getRectangle: function(task, view){
              if (task.start_baseline_date_4 && task.end_baseline_date_4) {
                return gantt.getTaskPosition(task, task.start_baseline_date_4, task.end_baseline_date_4);
              }
              return null;
            }
          },
          id: 4444
        });
      
        // end  adding baseline display
        gantt.attachEvent("onTaskLoading", function (task) {
          task.start_baseline_date_4 = gantt.date.parseDate(task.start_baseline_date_4, "xml_date");
          task.end_baseline_date_4 = gantt.date.parseDate(task.end_baseline_date_4, "xml_date");
          return true;
      
        });
    
        gantt.templates.rightside_text = function (start, end, task) {
          if (task.end_baseline_date_4) {
            if (end.getTime() > task.end_baseline_date_4.getTime()) {
              var overdue = Math.ceil(Math.abs((end.getTime() - task.end_baseline_date_4.getTime()) / (24 * 60 * 60 * 1000)));
              var text = "<b>Overdue_4: " + overdue + " days</b>";
              return text;
            }
          }
        };
        gantt.refreshData();
        gantt.render();
      break;
      
      case "showbaseline_5":
        gantt.removeTaskLayer(1111)
        gantt.removeTaskLayer(2222)
        gantt.removeTaskLayer(3333)
        gantt.removeTaskLayer(4444)
       
        gantt.addTaskLayer({
          renderer: {
            render: function draw_planned(task) {
              if (task.start_baseline_date_5 && task.end_baseline_date_5 ) {
                var sizes = gantt.getTaskPosition(task, task.start_baseline_date_5, task.end_baseline_date_5);
                var el = document.createElement('div');
                el.className = 'baseline_5';
                el.style.left = sizes.left + 'px';
                el.style.width = sizes.width + 'px';
                el.style.top = sizes.top + gantt.config.bar_height + 13 + 'px';
                return el;
              }
              return false;
            },
            // define getRectangle in order to hook layer with the smart rendering
            getRectangle: function(task, view){
              if (task.start_baseline_date_5 && task.end_baseline_date_5) {
                return gantt.getTaskPosition(task, task.start_baseline_date_5, task.end_baseline_date_5);
              }
              return null;
            }
          },
          id: 5555
        });
      
        // end  adding baseline display
        gantt.attachEvent("onTaskLoading", function (task) {
          task.start_baseline_date_5 = gantt.date.parseDate(task.start_baseline_date_5, "xml_date");
          task.end_baseline_date_5 = gantt.date.parseDate(task.end_baseline_date_5, "xml_date");
          return true;
      
        });
    
        gantt.templates.rightside_text = function (start, end, task) {
          if (task.end_baseline_date_5) {
            if (end.getTime() > task.end_baseline_date_5.getTime()) {
              var overdue = Math.ceil(Math.abs((end.getTime() - task.end_baseline_date_5.getTime()) / (24 * 60 * 60 * 1000)));
              var text = "<b>Overdue_5: " + overdue + " days</b>";
              return text;
            }
          }
        };
        
        gantt.refreshData();
        gantt.render();
      break;
      default:
        gantt.removeTaskLayer(1111)
        gantt.removeTaskLayer(2222)
        gantt.removeTaskLayer(3333)
        gantt.removeTaskLayer(4444)
        gantt.removeTaskLayer(5555)
        gantt.templates.rightside_text = function (start, end, task) {
          /* console.log(task.owner_id, "text de recurso") */
          return getResourceNames(gantt.serverList("resourceList"), task.owner_id);
        };
        gantt.refreshData();
        gantt.render();
        break;
    }

  },[showBaselineGantt]) 

  const handleTitleLb = (lba,lbc)=>{
    const evalDate = (lba,lbc) =>{
      if( lba &&  lbc ) {
        const daylba = Number(lba.split('-')[0])
        const monthlba = Number(lba.split('-')[1]) - 1
        const yearlba = Number(lba.split('-')[2])
  
        const daylbc = Number(lbc.split('-')[0])
        const monthlbc = Number(lbc.split('-')[1]) - 1
        const yearlbc = Number(lbc.split('-')[2])
  
        const lbaComp = (new Date(yearlba, monthlba, daylba)).getTime()
        const lbcComp = (new Date(yearlbc, monthlbc, daylbc)).getTime()

        return lbaComp > lbcComp ? true : false
      }
    }
    
    switch (evalDate(lba,lbc)) {
      case true :
        return `Fecha Actualización : ${lba}`
        break;
      case false :
        return `Fecha Creación : ${lbc}`
        break;
    
      default:
        break; 
    }
  }
  const [key, setKey] = useState('visualization');

  const handleSelectColorLB = (lb) => {
    switch (lb) {
      case "showbaseline_1":
          return 'yellow'
      case "showbaseline_2":
        return 'pink'
      case "showbaseline_3":
          return '#0ca30a'
      case "showbaseline_4":
        return 'red'
      case "showbaseline_5":
        return '#00B1C4'
      default:
        break;
    }
  }

  const sendFile = (file) => {
    
     gantt.importFromMSProject({
        data : file,
        callback : (project)=>{
          if(project) {
            gantt.clearAll()
            if(project.config.duration_unit) {
              gantt.config.duration_unit = project.config.duration_unit
            }
            gantt.parse(project.data)
          }
        }
      })
  }
   
  const handleFileChange  = (e) =>{
    const file = fileinput.current.files[0]
    e.preventDefault()
    console.log(fileinput.current.files[0])
    
    if(file){
      sendFile(file)
    }
  }
  // enable / disabled autoschedule
  useEffect(()=>{

    gantt.config.auto_scheduling = activeAutoSchedule; //1
    gantt.config.auto_scheduling_strict = activeAutoSchedule;
    gantt.config.auto_scheduling_compatibility = activeAutoSchedule;
    gantt.config.auto_scheduling_initial = activeAutoSchedule;

  },[activeAutoSchedule])

  const handleAutoScheduleOption = () =>{
    if(!activeAutoSchedule) {
      swal({
        title: "Activar Schedule",
        text: "¿Quieres activar la progrmación automatica?, al activarla cuando modifiques la fecha una actividad, automaticamente moverla las actividades que esten vinculadas.",
        icon: "info",
        buttons: {
          cancel: "Cancel",
          confirm: "Activar"
        }
      }).then((atv) => {
        if (atv) {
          setActiveAutoSchedule(true)
          //setActiveAutoSchedule((val)=> val =!val)
        } else {
          console.log("cancelo")
        }
      });
    }else{ 
      //setActiveAutoSchedule((val)=> val =!val)
      setActiveAutoSchedule(false)
    }
    }

    useEffect(()=>{
      if(props.dates ){
        const start_proje = props.dates.start_date_proje
        const end_proje  = props.dates.end_date_proje
        const parseDate_start = parse(start_proje,"dd/MM/yyyy", new Date());
        const parseDate_end = parse(end_proje,"dd/MM/yyyy", new Date());
        const parseDate_end_hours = setHours(setMinutes(parseDate_end,0),20)
        if(valiExcepcion.length > 0){
          const durationAllproj = gantt.calculateDuration(parseDate_start,parseDate_end_hours , "hours") / 8
        props.dates.setduration(durationAllproj)
        }else{
          props.dates.setduration("--")
        }
      }
      if(props.dateslb){
        if(props.dateslb.start_date_lb_proje){
        const start_proje_lb = props.dateslb.start_date_lb_proje
        const end_proje_lb  = props.dateslb.end_date_lb_proje
        const parseDate_start_lb = parse(start_proje_lb,"dd/MM/yyyy", new Date());
        const parseDate_end_lb = parse(end_proje_lb,"dd/MM/yyyy", new Date());
        const parseDate_end_lb_hours = setHours(setMinutes(parseDate_end_lb,0),20)
        if(valiExcepcion.length > 0){
          const durationAllproj_lb = gantt.calculateDuration(parseDate_start_lb,parseDate_end_lb_hours , "hours") / 8
          props.dateslb.setduration_lb(durationAllproj_lb)
        }else {
          props.dateslb.setduration_lb("--")
        }
        }
        
      }

    },[props.updatePctProje, valiExcepcion])

    const btnsFns = {
      zoomIn :  zoomInNew
    }


  const [viewTracking,setViewTracking] = useState(true)
  
  const handleTrackingView = (type)=>{
    if(type == "gantt"){
      setViewTracking(true)
    }else{
      setViewTracking(false)
    }
  }

/*    const toggleGrid = ()=>{
    setViewTracking(!viewTracking)
   } */
   

   useEffect(()=>{
    if(!viewTracking){
      gantt.config.layout = {
        css: "gantt_container",
        rows:[
            {
               cols: [
                {
                  // the default grid view  
                  view: "grid",  
                  scrollX:"scrollHor", 
                  scrollY:"scrollVer"
                },
                { resizer: true, width: 1 },
                {
                  // the default timeline view
                  view: "timeline", 
                  scrollX:"scrollHor", 
                  scrollY:"scrollVer"
                },
                {
                  view: "scrollbar", 
                  id:"scrollVer"
                }
            ]},
            {
                view: "scrollbar", 
                id:"scrollHor"
            }
        ]
    }
      gantt.resetLayout()
      gantt.config.show_chart  = false
      gantt.render()
    }else { 

      gantt.config.layout = {
        css: "gantt_container",
        cols: [
          {
            width: 700,
            min_width: 300,
            rows: [
              { view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer",},
              { view: "scrollbar", id: "gridScroll", group: "horizontal" },
            ],
          },
          { resizer: true, width: 1 },
          {
            rows: [
              { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
              { view: "scrollbar", id: "scrollHor", group: "horizontal" },
            ],
          },
          { view: "scrollbar", id: "scrollVer" },
        ],
      }; 

      gantt.resetLayout()
      gantt.config.show_chart  = true
      gantt.render()

    }

   },[viewTracking, gantt])

   //funciones para filtros 
   const [filter, setFilter]= useState("all") 

   const filterAllTasks = () => setFilter("all")
   const filterProgress0 =()=> setFilter("progress_0")
   const filterProgress100 =()=> setFilter("progress_100")
   const filterDeleyedTasks =()=> setFilter("delayed")
//evento dispara el filtro
   /* useEffect(()=>{
    gantt.attachEvent("onBeforeTaskDisplay", (id,task)=>{
      if( filter === "all"){
        return true 
      }else if(filter === "progress_0"){
        return task.progress === 0
      }else if(filter === "progress_100"){
        return task.progress === 1
      }
      return false

    })
    gantt.render()
   },[filter]) */

  return (
    <>
      <div id="show-btns-gantt" className="bg-light d-flex flex-column align-items-center">
        <Container fluid className="bg-light">
          <Row>
            <Col className="pt-3 pb-2">
              <div className="d-flex align-items-center group-btns-ganttdx-1 ">
                <div className="group-btns-ganttdx">
                 {/*  iniciar en tabnav */}
                 <Tabs 
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-2 "
                   /*  variant="pills" */
                    style={{display:"flex", gap:10}} 
                  >
                    <Tab id="visualization-test-margin" eventKey="visualization" title="Visualización">
                      <div className="d-flex general-btn-gantt">
                        <Button
                          className="mr-2 btn-1nos"
                          variant="outline-dark"
                          size="sm"
                          onClick={btnUndo}
                          title={t("schedule_gantt_undo")}
                        >
                          <i className="fa fa-undo"></i>
                        </Button>
                        <Button
                          className="mr-2 btn-1nos"
                          variant="outline-dark"
                          size="sm"
                          onClick={btnRedo}
                          title={t("schedule_gantt_redo")}
                        >
                          <i className="fa fa-redo"></i>
                        </Button>
                        <Button
                          className="mr-2 btn-1nos"
                          variant="outline-dark"
                          size="sm"
                          onClick={collapseAllTask}
                          title={t("schedule_gantt_collapse")}
                        >
                          <i className="fa fa-angle-double-up"></i>
                        </Button>
                        <Button
                          className="mr-2 btn-1nos"
                          variant="outline-dark"
                          size="sm"
                          onClick={expandAllTask}
                          title={t("schedule_gantt_expand")}
                        >
                          <i className="fa fa-angle-double-down"></i>
                        </Button>
                        <Button
                          className="btn-gantt-reca btn-2nos"
                          variant="outline-dark"
                          size="sm"
                          onClick={zoomIn}
                        >
                          Zoom <i className="fa fa-plus"></i>
                        </Button>
                        <Button
                          variant="outline-dark"
                          size="sm"
                          onClick={zoomOut}
                          className="mx-2 btn-gantt-resour btn-2nos"
                        >
                          Zoom <i className="fa fa-minus"></i>
                        </Button>
                        <Dropdown
                          className="d-flex btn-4nos mx-1"
                          /*  variant="outline-dark" */
                          size="sm"
                        >
                          <Dropdown.Toggle
                            className="px-2 py-0 m-0 btn-4nos"
                            variant="outline-dark"
                            id="dropdown-basic"
                            style={{fontSize:"14px"}}
                          >
                            {`Duración por: ${(showDuration)?"hrs":"días"} `}
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="outline-dark">
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={()=>{showDurationBtn("hours")}}
                            >
                              Días
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={()=>{showDurationBtn("days")}}
                            >
                              Horas
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown
                          className="d-flex btn-4nos mx-1"
                          size="sm"
                        >
                          <Dropdown.Toggle
                            className="px-2 py-0 m-0 btn-4nos d-none"
                            variant="outline-dark"
                            id="dropdown-basic"
                            style={{fontSize:"14px"}}
                          > Filtrar:
                           {/*  {`Filtro : ${(viewTracking)? "Gantt" :"Tracking"}`} */}
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="outline-dark">
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={filterAllTasks}
                              /* onClick={()=>{handleTrackingView("gantt")}} */
                            >
                              Ver todas
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={filterProgress0}
                             /*  onClick={()=>{handleTrackingView("tracking")}} */
                            >
                              Progresso 0%
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={filterProgress100}
                              /* onClick={()=>{handleTrackingView("tracking")}} */
                            >
                              Progreso 100% 
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={filterDeleyedTasks}
                              /* onClick={()=>{handleTrackingView("tracking")}} */
                            >
                              Atrasadas 
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown
                          className="d-flex btn-4nos mx-1"
                          size="sm"
                        >
                          <Dropdown.Toggle
                            className="px-2 py-0 m-0 btn-4nos"
                            variant="outline-dark"
                            id="dropdown-basic"
                            style={{fontSize:"14px"}}
                          >
                            {`Seguimiento : ${(viewTracking)? "Gantt" :"Tracking"}`}
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="outline-dark">
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={()=>{handleTrackingView("gantt")}}
                            >
                              Gantt
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={()=>{handleTrackingView("tracking")}}
                            >
                              Tracking
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Tab>
                    <Tab className="mx-1" eventKey="criticalRoad" title="Ruta crítica">
                      <div className="d-flex general-btn-gantt">
                        <Button
                          className="mr-2"
                          variant="outline-dark btn-3nos"
                          size="sm"
                          onClick={(e) => updateCriticalPath(e)}
                        >
                          {t("schedule_gantt_showcp")}
                        </Button>
                      </div>
                    </Tab>
                    <Tab className="mx-1" eventKey="action" title="Acciones" >
                      <div  className="d-flex general-btn-gantt btn-actions"> 
                        {/* <form onSubmit={handleFileChange}><input variant="outline-dark" ref={fileinput} type="file" id="mspFile" name="filemsp"  accep=".mpp,.xml" title="Import From MSP"/>
                        <button type="submit">Load MPP</button>
                        </form> */}
                        <Dropdown
                          className="d-flex btn-4nos mx-1"
                          size="sm"
                        >
                          <Dropdown.Toggle
                            className="px-2 py-0 m-0 btn-4nos"
                            variant="outline-dark"
                            id="dropdown-basic"
                            style={{fontSize:"14px"}}
                          >
                            {t("schedule_gantt_export")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="outline-dark">
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={() => handleExportMSP("MSproject")}
                            >
                              MSP
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={() => handleExportMSP("Excel")}
                            >
                              Excel
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={() => handleExportMSP("PDF")}
                            >
                              PDF
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown
                          className="d-flex btn-4nos mx-1"
                          /*  variant="outline-dark" */
                          size="sm"
                        >
                          <Dropdown.Toggle
                            className="px-2 py-0 m-0 btn-4nos"
                            variant="outline-dark"
                            id="dropdown-basic"
                            style={{fontSize:"14px"}}
                          >
                            {t("schedule_gantt_group")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="outline-dark">
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={() => {
                                showGroups();
                              }}
                            >
                              Gantt
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant="outline-dark"
                              onClick={() => {
                                showGroups("indicator");
                              }}
                            >
                              {t("schedule_indicator")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <DropdownButton
                        as={ButtonGroup}
                        autoClose="outside"
                        drop="up"
                        //id={1}
                        size="sm"
                        className="btn-4nos mx-1"
                        variant="outline-dark"
                        title={t("schedule_gantt_showoption")}
                      >
                        <div style={{maxHeight:"200px", overflowY:"scroll"}}>
                        <Dropdown.Item as="div" eventKey="1">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeWbs}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="wbs"
                              name="group1"
                              type="checkbox"
                              id={1}
                              /* onChange={()=> handleCheck(setActiveWbs)} */
                              onChange = {()=> toggleColum("activeWbs")}

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="2">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeUnique}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="UID"
                              name="group2"
                              type="checkbox"
                              id={2}
                              /* onChange={()=> handleCheck(setActiveUnique)} */
                              onChange = {()=> toggleColum("activeUnique")}

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="3">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeTask}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_activity")}
                              name="group3"
                              type="checkbox"
                              id={3}
                             /*  onChange={()=> handleCheck(setActiveTask)} */
                              onChange = {()=> toggleColum("activeTask")}

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="4">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeStart}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_start")}
                              name="group4"
                              type="checkbox"
                              id={4}
                              /* onChange={()=> handleCheck(setActiveStart)} */
                              onChange = {()=> toggleColum("activeStart")}

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="5">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeEnd}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_end")}
                              name="group5"
                              type="checkbox"
                              id={5}
                            /*   onChange={()=> handleCheck(setActiveEnd)} */
                              onChange = {()=> toggleColum("activeEnd")}

                            />
                          </div>
                        </Dropdown.Item>
                       {/*  <Dropdown.Item as="div" eventKey="6">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeDuration}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_duration")}
                              name="group6"
                              type="checkbox"
                              id={6}
                              onChange = {()=> toggleColum("activeDuration")}
                            />
                          </div>
                        </Dropdown.Item> */}
                        <Dropdown.Item as="div" eventKey="7">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeCost}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_cost")}
                              name="group7"
                              type="checkbox"
                              id={7}
                              onChange = {()=> toggleColum("activeCost")}
                              /* onChange={()=> handleCheck(setActiveCost)} */

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="8">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeCostReal}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_actualCost")}
                              name="group8"
                              type="checkbox"
                              id={8}
                              onChange = {()=> toggleColum("activeCostReal")}
                              /* onChange={()=> handleCheck(setActiveCostReal)} */

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="9">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activePrede}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_predecessor")}
                              name="group9"
                              type="checkbox"
                              id={9}
                              onChange = {()=> toggleColum("activePrede")}
                              /* onChange={()=> handleCheck(setActivePrede)} */

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="10">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeReal}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_gantt_progress")}
                              name="group10"
                              type="checkbox"
                              id={10}
                              onChange = {()=> toggleColum("activeReal")}
                              /* onChange={()=> handleCheck(setActiveReal)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="11">
                          <div className="d-flex align-items-center">
                            <Form.Check
                                checked={!showColumns.activePlan}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="%Plan"
                              name="group11"
                              type="checkbox"
                              id={11}
                              onChange = {()=> toggleColum("activePlan")}
                            /*   onChange={()=> handleCheck(setActivePlan)} */

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="12">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.activeIndi}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label={t("schedule_indicator")}
                              name="group12"
                              type="checkbox"
                              id={12}
                              onChange = {()=> toggleColum("activeIndi")}

                              /* onChange={()=> handleCheck(setActiveIndi)} */
                            />
                          </div>
                        </Dropdown.Item>
                      {/*   Linea base 1 */}
                        <Dropdown.Item as="div" eventKey="13">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_start_1}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Inicio LB 1"
                              name="group13"
                              type="checkbox"
                              id={13}
                              onChange = {()=> toggleColum("actLB_start_1")}
                              /* onChange={()=>handleCheck(setActLB_start_1)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="14">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_end_1}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Fin LB 1"
                              name="group14"
                              type="checkbox"
                              id={14}
                              onChange = {()=> toggleColum("actLB_end_1")}
                              /* onChange={()=>handleCheck(setActLB_end_1)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="15">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_duration_1}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Duración LB 1"
                              name="group15"
                              type="checkbox"
                              id={15}
                              onChange = {()=> toggleColum("actLB_duration_1")}
                            /*   onChange={()=>handleCheck(setActLB_duration_1)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="16">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_cost_1}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Costo LB 1"
                              name="group16"
                              type="checkbox"
                              id={16}
                              onChange = {()=> toggleColum("actLB_cost_1")}
                              /* onChange={()=>handleCheck(setActLB_cost_1)} */
                            />
                          </div>
                        </Dropdown.Item>
                      {/*   Linea base 2 */}
                      <Dropdown.Item as="div" eventKey="17">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_start_2}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Inicio LB 2"
                              name="group13"
                              type="checkbox"
                              id={17}
                              onChange = {()=> toggleColum("actLB_start_2")}
                              /* onChange={()=>handleCheck(setActLB_start_2)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="18">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_end_2}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Fin LB 2"
                              name="group18"
                              type="checkbox"
                              id={18}
                              onChange = {()=> toggleColum("actLB_end_2")}
                             /*  onChange={()=>handleCheck(setActLB_end_2)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="19">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_duration_2}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Duración LB 2"
                              name="group19"
                              type="checkbox"
                              id={19}
                              onChange = {()=> toggleColum("actLB_duration_2")}
                             /*  onChange={()=>handleCheck(setActLB_duration_2)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="20">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_cost_2}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Costo LB 2"
                              name="group16"
                              type="checkbox"
                              id={20}
                              onChange = {()=> toggleColum("actLB_cost_2")}
                             /*  onChange={()=>handleCheck(setActLB_cost_2)} */
                            />
                          </div>
                        </Dropdown.Item>
                        {/*   Linea base 3 */}
                      <Dropdown.Item as="div" eventKey="21">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_start_3}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Inicio LB 3"
                              name="group21"
                              type="checkbox"
                              id={21}
                              onChange = {()=> toggleColum("actLB_start_3")}
                              /* onChange={()=>handleCheck(setActLB_start_3)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="22">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_end_3}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Fin LB 3"
                              name="group22"
                              type="checkbox"
                              id={22}
                              onChange = {()=> toggleColum("actLB_end_3")}
                              /* onChange={()=>handleCheck(setActLB_end_3)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="23">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_duration_3}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Duración LB 3"
                              name="group23"
                              type="checkbox"
                              id={23}
                              onChange = {()=> toggleColum("actLB_duration_3")}

                             /*  onChange={()=>handleCheck(setActLB_duration_3)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="24">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_cost_3}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Costo LB 3"
                              name="group24"
                              type="checkbox"
                              id={24}
                              onChange = {()=> toggleColum("actLB_cost_3")}

                            /*   onChange={()=>handleCheck(setActLB_cost_3)} */
                            />
                          </div>
                        </Dropdown.Item>
                        {/*   Linea base 4 */}
                      <Dropdown.Item as="div" eventKey="25">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_start_4}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Inicio LB 4"
                              name="group25"
                              type="checkbox"
                              id={25}
                              onChange = {()=> toggleColum("actLB_start_4")}
                              /* onChange={()=>handleCheck(setActLB_start_4)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="26">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_end_4}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Fin LB 4"
                              name="group36"
                              type="checkbox"
                              id={26}
                              onChange = {()=> toggleColum("actLB_end_4")}
                              /* onChange={()=>handleCheck(setActLB_end_4)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="27">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_duration_4}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Duración LB 4"
                              name="group27"
                              type="checkbox"
                              id={27}
                              /* onChange={()=>handleCheck(setActLB_duration_4)} */
                              onChange = {()=> toggleColum("actLB_duration_4")}

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="28">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_cost_4}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Costo LB 4"
                              name="group28"
                              type="checkbox"
                              id={28}
                              onChange = {()=> toggleColum("actLB_cost_4")}
                             /*  onChange={()=>handleCheck(setActLB_cost_4)} */
                            />
                          </div>
                        </Dropdown.Item>
                        {/*   Linea base 5 */}
                      <Dropdown.Item as="div" eventKey="29">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_start_5}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Inicio LB 5"
                              name="group29"
                              type="checkbox"
                              id={29}
                             /*  onChange={()=>handleCheck(setActLB_start_5)} */
                              onChange = {()=> toggleColum("actLB_start_5")}

                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="30">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_end_5}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Fin LB 5"
                              name="group30"
                              type="checkbox"
                              id={30}
                              /* onChange={()=>handleCheck(setActLB_end_5)} */
                              onChange = {()=> toggleColum("actLB_end_5")}
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="31">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_duration_5}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Duración LB 5"
                              name="group31"
                              type="checkbox"
                              id={31}
                              onChange = {()=> toggleColum("actLB_duration_5")}
                              /* onChange={()=>handleCheck(setActLB_duration_5)} */
                            />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item as="div" eventKey="32">
                          <div className="d-flex align-items-center">
                            <Form.Check
                              checked={!showColumns.actLB_cost_5}
                              className="w-100 d-flex justify-content-between flex-row-reverse mr-1"
                              inline
                              label="Costo LB 5"
                              name="group32"
                              type="checkbox"
                              id={32}
                              onChange = {()=> toggleColum("actLB_cost_5")}
                              /* onChange={()=>handleCheck(setActLB_cost_5)} */
                            />
                          </div>
                        </Dropdown.Item>
                        </div>
                        </DropdownButton>
                        <Button onClick={handleAutoScheduleOption} size="sm" variant="outline-dark">Auto Schedule : { activeAutoSchedule ?"Activo":"Inactivo" }</Button>
                      </div>
                    </Tab>
                    <Tab style={{margin:"20"}} className="mx-2" eventKey="baselineBtnMenu" title="Línea Base">
                      <div style={{alignItems:"center"}} className="d-flex general-btn-gantt btn-setbl-mobile"> 
                        <Button
                          className="mr-2 btn-setbl"
                          variant="outline-dark"
                          disabled = {taskForBaseline.length == 0 ? true : false}
                          size="sm"
                          title="Set Baseline"
                          onClick={handleShowBaseline}
                          /* onClick={expandAllTask}
                          
                          title={t("schedule_gantt_expand")} */
                          >
                          Establecer Línea base <i className="fa fa-grip-lines"></i>
                        </Button>
                        <Form.Control
                            style={{borderColor:'#343a40', backgroundColor:'transparent', color:'#343a40',width:'auto'}}
                            as="select"
                            name="showbaseline"
                            variant="outline-dark"
                            className="btn-seleclb"
                            size="sm"
                            onChange={(e) => {
                              console.log("entra show")
                              
                              inputChange(e, setShowBaselineGantt)
                              
                              
                            }}
                            >
                          <option value="showbaseline_0">Seleccionar Línea Base...</option>
                          <option value="showbaseline_1">Línea Base 1 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline, taskForBaseline[0]?.creation_dateLB1 )}</option>
                          <option value="showbaseline_2">Línea Base 2 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_2, taskForBaseline[0]?.creation_dateLB2 )}</option>
                          <option value="showbaseline_3">Línea Base 3 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_3, taskForBaseline[0]?.creation_dateLB3 )}</option>
                          <option value="showbaseline_4">Línea Base 4 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_4, taskForBaseline[0]?.creation_dateLB4 )}</option>
                          <option value="showbaseline_5">Línea Base 5 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_5, taskForBaseline[0]?.creation_dateLB5 )}</option>
                          
                        </Form.Control>
                        {(showBaselineGantt.showbaseline == "showbaseline_0" || showBaselineGantt == {}) ? ""
                          :
                          <div className="mx-3" >
                            {/* <ProgressBar style={{height:'8px'}}  now={100} variant='danger'/>
                            <span style={{visibility:"hidden"}} >Linea base seleccionada : </span> */}
                            <div style={{ backgroundColor:handleSelectColorLB(showBaselineGantt.showbaseline )}} className="lb_form_select"></div>
                            
                          </div>
                        }

                      </div>
                    </Tab>
                  </Tabs>

                  <div className="d-flex general-btn-gantt">
                  {/* aqui iba rc */}
                  </div>
                  <div className="d-flex mr-2 btn-4nos">
                    {/* aqui iba export */}
                  </div>
                  <div className="d-flex mr-2 btn-4nos">
                    {/* aqui iba group */}
                  </div>
                  <div className="d-flex mr-2 btn-4nos">
                   {/* seleccion de columnas */}
                  </div>
                  <div className="d-flex mr-2 btn-4nos">
                   {/* establecer lb */}
                  </div>
                  <div className="d-flex mr-2 btn-4nos">
                 {/* seleccionar lb */}

                  </div>

                </div>
              </div>
            </Col>
          </Row>
          <Row>
           
          </Row>
        </Container>
        <div style={{width:"100%", height:"600px", overflow:"hidden"}} >
          <div className="gantt_box_dxhtml" ref={ganttContainer}></div>
        </div>
      {/*   <div style={{width:"98%", height:"600px", overflow:"hidden"}}>
                        <GanttTest
                          uid= {uid} 
                          showColumns={showColumns}
                          showDuration= {showDuration}
                          btnsFns = {btnsFns}
                        />
        </div> */}
        
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="py-3 d-flex justify-content-between">
          <h5 className="mb-0">{taskEdit.taskName}</h5>
          {/* <button type="button" class="btn-close" aria-label="Close">X</button> */}
          <Button onClick={(e) => {
                props.setShow(false);
              }} variant="light">X</Button>
        </Modal.Header>
        <Modal.Body>
          <NotesModal taskEdit={taskEdit} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showBaseline}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-center" >
          <Modal.Title>
          <div className="d-flex flex-column align-items-center">
          <h5>{props?.projectName}</h5>
          <p className="mb-0 h6"> Establecer Linea Base </p>
          </div>
         
          </Modal.Title>
          
          {/* <button type="button" class="btn-close" aria-label="Close"></button> */}
        </Modal.Header>
        <Modal.Body>
        
        <Form.Control
              as="select"
              name="baseline" //editar de acuerdo a la base
              className="mb-2"
              onChange={(e) => {
                console.log("entra")
                inputChange(e, setSaveBaseline);

              }}
              /* value={mainData.IdCostosAgil || ""} */
              /* required */
            >
              <option value="">Seleccionar Línea Base...</option>
              <option value="baseline_1">Línea Base 1 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline, taskForBaseline[0]?.creation_dateLB1 )}</option>
              <option value="baseline_2">Línea Base 2 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_2, taskForBaseline[0]?.creation_dateLB2 )}</option>
              <option value="baseline_3">Línea Base 3 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_3, taskForBaseline[0]?.creation_dateLB3 )}</option>
              <option value="baseline_4">Línea Base 4 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_4, taskForBaseline[0]?.creation_dateLB4 )}</option>
              <option value="baseline_5">Línea Base 5 {handleTitleLb(taskForBaseline[0]?.modification_date_baseline_5, taskForBaseline[0]?.creation_dateLB5 )}</option>
              {/* {_.filter(ddlForm, { DDL: "COSTOSAGIL" }).map((item) => {
                return (
                  <option key={item.ID} value={item.ID}>
                    {item.VALOR}
                  </option>
                ); 
              })} */}
            </Form.Control>
            <div className="d-flex justify-content-end ">
            <DropdownButton
                    as={ButtonGroup}
                    autoClose="outside"
                    drop="end"
                    size="md"
                    disabled={savebaseline.baseline == "" ? true : false}
                    variant="light"
                    title="Opciones"
                  >
                    <Dropdown.Item 
                      as="button" 
                      onClick={(e)=>handleSetBaseline(e)}
                      eventKey="1">
                      Establecer Línea Base 
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={(e) => handleRemoveBaseline(e)} eventKey="3">
                      Remover Línea Base
                    </Dropdown.Item>
                    
          </DropdownButton>
            </div>
            <hr/>
            <h5>Copiar Línea base </h5>
            <h6>De: </h6>
            <Form.Control
              as="select"
              id={2}
              name="copy_baseline_from" //editar de acuerdo a la base
              className="mb-2"
              onChange={(e) => {
                console.log("entra copiar baseline1")
                inputChange(e, setSaveBaselineCopyFrom);
                

              }}
            >
              <option value="">Seleccionar Línea Base...</option>
              <option value="baseline_1">Línea Base 1 ({taskForBaseline[0]?.modification_date_baseline})</option>
              <option value="baseline_2">Línea Base 2 ({taskForBaseline[0]?.modification_date_baseline_2})</option>
              <option value="baseline_3">Línea Base 3 ({taskForBaseline[0]?.modification_date_baseline_3})</option>
              <option value="baseline_4">Línea Base 4 ({taskForBaseline[0]?.modification_date_baseline_4})</option>
              <option value="baseline_5">Línea Base 5 ({taskForBaseline[0]?.modification_date_baseline_5})</option>
              {/* {_.filter(ddlForm, { DDL: "COSTOSAGIL" }).map((item) => {
                return (
                  <option key={item.ID} value={item.ID}>
                    {item.VALOR}
                  </option>
                ); 
              })} */}
            </Form.Control>
            <h6>A:</h6>
            <Form.Control
              as="select"
              name="copy_baseline_to" //editar de acuerdo a la base
              className="mb-2"
              onChange={(e) => {
                inputChange(e, setSaveBaselineCopyTo);

              }}
            >
              <option value="">Seleccionar Línea Base...</option>
              <option value="baseline_1">Línea Base 1 ({taskForBaseline[0]?.modification_date_baseline})</option>
              <option value="baseline_2">Línea Base 2 ({taskForBaseline[0]?.modification_date_baseline_2})</option>
              <option value="baseline_3">Línea Base 3 ({taskForBaseline[0]?.modification_date_baseline_3})</option>
              <option value="baseline_4">Línea Base 4 ({taskForBaseline[0]?.modification_date_baseline_4})</option>
              <option value="baseline_5">Línea Base 5 ({taskForBaseline[0]?.modification_date_baseline_5})</option>
              {/* {_.filter(ddlForm, { DDL: "COSTOSAGIL" }).map((item) => {
                return (
                  <option key={item.ID} value={item.ID}>
                    {item.VALOR}
                  </option>
                ); 
              })} */}
            </Form.Control>

          <Button 
            variant="info"
            disabled = { (savebaselineCopyFrom?.copy_baseline_from == "" || savebaselineCopyTo?.copy_baseline_to == "") ? true : false }
            onClick={(e) => handleCopyBaseline(e)}
            >Copiar 
          </Button>

       
          
        </Modal.Body>
        <Modal.Footer> 

        <Button variant="secondary" onClick={handleCloseBaseline}>Cancelar</Button>
       
       
        </Modal.Footer>
      </Modal>
      
    </>
  );
};

export default GanttDxClass;
