import _ from "lodash";
import React, { useCallback, useEffect, useMemo,useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { inputChange, saveData } from "../helpers";
import { selectTooltip, selectTooltip_en } from "../utils/selectTooltip";
import { useTranslation } from "react-i18next";

const FormProjectGeneral = (props) => {
  const [t,i18n] = useTranslation("create")
  const { mainData, ddlForm, setMainData, currentProj, davipola } = props;
  const [country, setCountry] = useState("")
  const [departament, setDepartment] = useState("")
  const allProjectsData = useSelector((state) => state.projects.projects);

  const codExists = useMemo(() => {
    let findProject = allProjectsData.find(
      (project) => project.CODProyecto === mainData.CODProyecto
    );
    if (findProject && currentProj?.CODProyecto !== mainData.CODProyecto) {
      return true;
    }
    return false;
  }, [mainData, currentProj]);

  const handleChangeCOD = useCallback(
    (e) => {
      if (!e.target.value) {
        e.target.value = currentProj?.CODProyecto || "";
      }
      inputChange(e, setMainData);
    },
    [currentProj]
  );

  let history = useHistory();

  useEffect(()=>{
    if(!mainData.new){
      setDepartment(mainData.Departamento)
    }
  },[mainData.Departamento])

  return (
    <Form
      onSubmit={(e) => {
        console.log("submiting");
        e.preventDefault();
        const success = () => {
          swal(
            t("project_modal_done_1"),
            t("project_modal_done_2"),
            "success"
          ).then((val) => {
            history.push("/projects");
          });
        };
        saveData("projects", mainData, success);
      }}
    >
      <h6>{t("project_title_generaldata")}</h6>
      <Row>
        <Col md={3} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(11): selectTooltip_en(11)}>
            {t("project_formdata_cod")}<small className="text-danger">*</small>
          </Form.Label>
          <Form.Control
            name="CODProyecto"
            // onChange={(e) => {
            //   // if (!e.target.value) {
            //   //   e.target.value = currentProject?.CODProyecto || "";
            //   // }
            //   inputChange(e, setMainData);
            // }}
            onChange={handleChangeCOD}
            value={mainData.CODProyecto || ""}
            required
          />
          {codExists ? (
            <Form.Text className="text-danger">
              {t("project_formdata_cod_already_exists")}
            </Form.Text>
          ) : null}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(12): selectTooltip_en(12)}>
            {t("project_formdata_name")}<small className="text-danger">*</small>
          </Form.Label>
          <Form.Control
            name="Nombre"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.Nombre || ""}
            required
          />
        </Col>
        <Col md={3} className="mb-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://projects.co.pwc.com/testagilidad/test/index.html#/"
          >
            <i
              data-tip={(t("create_lenguaje") == "es")?selectTooltip(32): selectTooltip_en(32)}
              className="fa fa-question-circle text-info mr-2"
            ></i>
          </a>
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(13) : selectTooltip_en(13)}>
            {t("project_formdata_methodology")}<small className="text-danger">*</small>
          </Form.Label>
          <Form.Control
            as="select"
            name="Metodologia"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.Metodologia || ""}
            required
            disabled={mainData.new ? false : true}
          >
            <option value="">{t("project_formdata_methodology_select")}...</option>
            {_.filter(ddlForm, { DDL: "METODOLOGIA" }).map((item) => {
              return (
                <option key={item.ID} value={item.ID}>
                  {item.VALOR}
                </option>
              );
            })}
           {/*  <option value="Tradicional">{t("project_formdata_methodology_traditional")}</option>
            <option value="Ágil">{t("project_formdata_methodology_agil")}</option> */}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(14): selectTooltip_en(14)}>{t("project_formdata_description")}</Form.Label>
          <Form.Control
            as="textarea"
            name="Descripcion"
            rows="3"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.Descripcion || ""}
          />
        </Col>
      </Row>
      <Row>
        <Col md={mainData.Metodologia === "Ágil" ? 3 : 4} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(15): selectTooltip_en(15)}>
            {t("project_formdata_type")}<small className="text-danger">*</small>
          </Form.Label>
          <Form.Control
            as="select"
            name="IdTipoProyecto"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.IdTipoProyecto || ""}
            required
          >
            <option value="">{t("project_formdata_methodology_select")}...</option>
            {_.filter(ddlForm, { DDL: "TIPO_PROYECTO" }).map((item) => {
              return (
                <option key={item.ID} value={item.ID}>
                  {item.VALOR}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        <Col md={mainData.Metodologia === "Ágil" ? 3 : 4} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(17): selectTooltip_en(17)}>
            {mainData.Metodologia === "Ágil" ? "Product Owner" : t("project_formdata_manager")}
            <small className="text-danger">*</small>
          </Form.Label>
          <Form.Control
            as="select"
            name="Gerente"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.Gerente || ""}
            required
          >
            <option value="">{t("project_formdata_methodology_select")}...</option>
            {_.filter(ddlForm, { DDL: "USUARIOS" }).map((item) => {
              return (
                <option key={item.ID} value={item.ID}>
                  {item.VALOR}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        {mainData.Metodologia === "Ágil" && (
          <>
          <Col md={3} className="mb-3">
            <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(33): selectTooltip_en(33)}>
              Scrum Master<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              as="select"
              name="ScrumMaster"
              onChange={(e) => {
                inputChange(e, setMainData);
              }}
              value={mainData.ScrumMaster || ""}
              required
            >
              <option value="">{t("project_formdata_methodology_select")}...</option>
              {_.filter(ddlForm, { DDL: "USUARIOS" }).map((item) => {
                return (
                  <option key={item.ID} value={item.ID}>
                    {item.VALOR}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col md={3} className="mb-3">
            <Form.Label >
            {t("project_formdata_money")}<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              as="select"
              name="IdCostosAgil" //editar de acuerdo a la base
              onChange={(e) => {
                inputChange(e, setMainData);
              }}
              value={mainData.IdCostosAgil || ""}
              required
            >
              <option value="">{t("project_formdata_methodology_select")}...</option>
              {_.filter(ddlForm, { DDL: "COSTOSAGIL" }).map((item) => {
                return (
                  <option key={item.ID} value={item.ID}>
                    {item.VALOR}
                  </option>
                ); // valores de base
              })}
            </Form.Control>
          </Col>
          </>
        )}
        <Col md={4} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(16): selectTooltip_en(16)}>
            {t("project_formdata_country")}<small className="text-danger">*</small>
          </Form.Label>
          <Form.Control
            as="select"
            name="IdPais"
            onChange={(e) => {
             /*  console.log(e.target.value) */
              if(e.target.value == 1){
                setCountry("COL")
              }else{
                setCountry("")
              }
              inputChange(e, setMainData);
            }}
            value={mainData.IdPais || ""}
            required
          >
            <option value="">{t("project_formdata_methodology_select")}...</option>
            {_.filter(ddlForm, { DDL: "PAIS" }).map((item) => {
              return (
                <option key={item.ID} value={item.ID}>
                  {item.VALOR}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        {(country === "COL" || mainData.IdPais == 1) && (
          <>
          <Col md={3} className="mb-3">
            <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(33): selectTooltip_en(33)}>
              Departamento
            </Form.Label>
            <Form.Control
              as="select"
              name="Departamento"
              onChange={(e) => {
                setDepartment(e.target.value)
                inputChange(e, setMainData);
              }}
              value={mainData.Departamento || ""}
              
            >
              <option value="">{t("project_formdata_methodology_select")}...</option>
              {
               _.filter(davipola,{Entidad :"Departamento"}).map((item)=>{
                  return <option key={item.Id_Espacial} value={item.Departamento}>
                          {item.Departamento}
                        </option>
               })
              }
            </Form.Control>
          </Col>
          <Col md={3} className="mb-3">
            <Form.Label >
            Municipio
            </Form.Label>
            <Form.Control
              as="select"
              name="Municipio" //editar de acuerdo a la base
              onChange={(e) => {
                inputChange(e, setMainData);
              }}
              value={mainData.Municipio || ""}
            >
              <option value="">{t("project_formdata_methodology_select")}...</option>
              {
               _.filter(davipola,{ Departamento: departament }).map((item)=>{
                  return <option key={item.Id_Espacial} value={item.Municipio}>
                          {item.Municipio}
                        </option>
               })
              }
            </Form.Control>
          </Col>
          </>
        )}
       
      </Row>
      <hr />
      <h6>{t("project_title_projectprofile")}</h6>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(18) : selectTooltip_en(18)}>{t("project_formdata_justification")}</Form.Label>
          <Form.Control
            as="textarea"
            name="Justificacion"
            rows="3"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.Justificacion || ""}
          />
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(19): selectTooltip_en(19)}>{t("project_formdata_antecedents")}</Form.Label>
          <Form.Control
            as="textarea"
            name="Antecedentes"
            rows="3"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.Antecedentes || ""}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(20): selectTooltip_en(20)}>{t("project_formdata_generalobjective")}</Form.Label>
          <Form.Control
            as="textarea"
            name="ObjGeneral"
            rows="3"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.ObjGeneral || ""}
          />
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label data-tip={(t("create_lenguaje") == "es")? selectTooltip(21) : selectTooltip_en(21)}>
            {t("project_formdata_specificobjectives")}
          </Form.Label>
          <Form.Control
            as="textarea"
            name="ObjEspecificos"
            rows="3"
            onChange={(e) => {
              inputChange(e, setMainData);
            }}
            value={mainData.ObjEspecificos || ""}
          />
        </Col>
      </Row>
      <Button
        variant="success"
        type="submit"
        // className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
        className="position-fixed"
        style={{ bottom: "1rem", right: "1rem" }}
        disabled={codExists}
      >
        <i className="fa fa-save mr-2"></i>{t("project_button_save")}
      </Button>
    </Form>
  );
};

export default FormProjectGeneral;
